import { store } from "app/store";
import * as axios from "axios";
import { AxiosRequestHeaders, InternalAxiosRequestConfig  } from "axios";
import { selectAuthToken } from "slices/auth-token-slice";

const instance = axios.default;
instance.interceptors.request.use((config: InternalAxiosRequestConfig ) => {
  const userToken: string | null = selectAuthToken(store.getState());
  return (
    {
      ...config,
      headers: {
        ...config.headers,
        ...(userToken ? { Authorization: `Bearer ${userToken}` } : {}),
      } as AxiosRequestHeaders,
    }
  );
});

export { instance };
