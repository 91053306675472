import Calendar from "components/calendar";
import Section from "components/section";
import Ui from "components/ui";
import React, { ReactElement } from "react";
import { connect } from "react-redux";
import BasePage from "../base-page";
import { CardDeck } from "components/card-deck";
import { useJournalPage } from "./hooks";
import { POST_PAGE_SIZE } from "slices/post-slice";
import "./index.scss";
import { Post } from "models";
import { getDateString } from "utils";
 
const JournalPage: React.FC = (): ReactElement => {
  const {
    isLoading,
    page,
    pageTotal,
    posts,
    setCurrMonthYear,
    setPage,
    showModal,
  } = useJournalPage();
  return (
    <BasePage>
      <Section centered>
        <Ui.Lines value="I post things on here from time to time. Check back later if there's nothing new." />
        <Calendar onSettle={setCurrMonthYear} />
      </Section>
      <Section>
        <CardDeck
          cards={posts}
          isLoading={isLoading}
          onPageChange={setPage}
          page={page}
          pageLimit={POST_PAGE_SIZE}
          renderCard={(post: Post) => (
            <Ui.Card
              entityId={post.id}
              imgAlt={post.imageAlt}
              imgUrl={post.thumbnailUrl}
              label={getDateString(new Date(post.createdAt))}
              onClick={showModal}
            />
          )}
          showControls
          totalPages={pageTotal}
        />
      </Section>
    </BasePage>
  );
};

export default connect()(JournalPage);
