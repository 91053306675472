import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "app/store";

export interface NotifierMessage {
  flavor: 'default' | 'error' | 'success';
  message: string;
}

interface NotifierMessageSlice {
  queue: NotifierMessage[];
}

const initialState: NotifierMessageSlice = {
  queue: [],
};

export const notifierMessageSlice = createSlice({
  name: "notifier",
  initialState,
  reducers: {
    clearMessages: (state: NotifierMessageSlice) => {
      state.queue = [];
    },
    setError: (
      state: NotifierMessageSlice,
      action: PayloadAction<string | string[]>
    ) => {
      state.queue = ([] as string[]).concat(action.payload).map((m: string) => ({
        flavor: 'error',
        message: m,
      }));
    },
    setMessages: (
      state: NotifierMessageSlice,
      action: PayloadAction<string | NotifierMessage | (string | NotifierMessage)[]>
    ) => {
      state.queue = ([] as (string | NotifierMessage)[]).concat(action.payload).map((m: string | NotifierMessage) => {
        if (typeof m === 'string') return {
          flavor: 'default',
          message: m,
        };
        return m;
      });
    },
    setSuccess: (
      state: NotifierMessageSlice,
      action: PayloadAction<string | string[]>
    ) => {
      state.queue = ([] as string[]).concat(action.payload).map((m: string) => ({
        flavor: 'success',
        message: m,
      }));
    },
  },
});

export const { clearMessages, setError, setMessages, setSuccess } = notifierMessageSlice.actions;

export const selectNotifierMessages = (state: AppState) =>
  state.notifierMessage.queue;

export default notifierMessageSlice.reducer;
