import { ModalType, Post } from "models";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import PostService from "services/post/service";
import { ActiveMockPostModalState, ActivePostModalState, selectActiveModal, setActiveModal } from "slices/active-modal-slice";
import { selectAuthToken } from "slices/auth-token-slice";
import { selectIsLoading, setIsLoading } from "slices/is-loading-slice";
import { PagePostMetadataState, selectPosts } from "slices/post-slice";
import { DEFAULT_DEBOUNCE_MS } from "utils";

export const useModal = () => {
  const activeModal = useSelector(selectActiveModal);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(setActiveModal(undefined));
  }, [dispatch]);

  return {
    onClose,
    isLoading,
    type: activeModal?.type,
  };
}

export const usePostStagingModalContent = () => {
  const activeModal = useSelector(selectActiveModal);
  const mockPost = useMemo(() => {
    if (!activeModal || activeModal.type !== ModalType.MockPost) return;
    return activeModal as ActiveMockPostModalState;
  }, [activeModal]);
  return {
    body: mockPost?.body,
    dataUrl: mockPost?.dataUrl,
    name: mockPost?.name,
  }
};

export const usePostModalContent = () => {
  const dispatch = useDispatch();

  const activeModal = useSelector(selectActiveModal);
  const allPosts = useSelector(selectPosts);
  const authToken = useSelector(selectAuthToken);

  const [hasImageLoaded, setHasImageLoaded] = useState<boolean>(false);

  const post: Post = useMemo(() => {
    if (!activeModal || activeModal.type !== ModalType.Post) return undefined;
    const activePost = activeModal as ActivePostModalState;
    const posts = Object.values(allPosts[activePost.year][activePost.month])
      .reduce((acc: Post[], curr: PagePostMetadataState) => ([
        ...acc,
        ...curr.list,
      ]), []);
    return (
      posts.find((p: Post) => p.id === activePost.id)
    );
  }, [activeModal, allPosts]);

  const [claps, setClaps] = useState<number>(post?.claps ?? 1);
  const [isPostPinned, setIsPostPinned] = useState<boolean>(post?.isPinned ?? false);

  const incrementClap = useCallback(() => setClaps((prevClaps) => prevClaps + 1), []);

  useEffect(() => {
    if (!authToken || !post || isPostPinned === post.isPinned) return;
    dispatch(setIsLoading(true));
    const timer = setTimeout(async () => {
      await PostService.update({
        post,
        newPost: {
          isPinned: isPostPinned,
        },
      }, dispatch);
    }, DEFAULT_DEBOUNCE_MS);
    return () => {
      dispatch(setIsLoading(false));
      clearTimeout(timer);
    }
  }, [authToken, dispatch, post, isPostPinned]);

  useEffect(() => {
    if (!post || claps <= post.claps) return;
    dispatch(setIsLoading(true));
    const timer = setTimeout(async () => {
      await PostService.clap({
        post,
        newClaps: claps,
      }, dispatch);
    }, DEFAULT_DEBOUNCE_MS);
    return () => {
      dispatch(setIsLoading(false));
      clearTimeout(timer);
    }
  }, [claps, dispatch, post]);

  useEffect(() => {
    dispatch(setIsLoading(!hasImageLoaded));
    return () => {
      dispatch(setIsLoading(false));
    }
  }, [dispatch, hasImageLoaded]);

  return {
    authToken,
    claps,
    hasImageLoaded,
    incrementClap,
    isPostPinned,
    post,
    setHasImageLoaded,
    setIsPostPinned,
  };
};
