import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "app/store";

interface AuthTokenState {
  value: string | null;
}

const initialState: AuthTokenState = {
  value: null,
};

export const authTokenSlice = createSlice({
  name: "authToken",
  initialState,
  reducers: {
    clearToken: (state: AuthTokenState) => {
      state.value = null
    },
    setAuthToken: (
      state: AuthTokenState,
      action: PayloadAction<string>
    ) => {
      state.value = action.payload;
    },
  },
});

export const { clearToken, setAuthToken } = authTokenSlice.actions;

export const selectAuthToken = (state: AppState) => state.authToken.value;

export default authTokenSlice.reducer;
