import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "app/store";
import { ModalType } from "models";

interface ActiveState {
  type: ModalType;
}

export interface ActivePostModalState extends ActiveState {
  id: string;
  month: number;
  year: number;
}

export interface ActiveMockPostModalState extends ActiveState {
  body: string;
  dataUrl: string;
  name: string;
}

type ActiveModalStates = ActivePostModalState | ActiveMockPostModalState | undefined;

interface ActiveModalState {
  value: ActiveModalStates;
}

const initialState: ActiveModalState = {
  value: undefined,
};

export const activeModalSlice = createSlice({
  name: 'activeModal',
  initialState,
  reducers: {
    setActiveModal: (
      state: ActiveModalState,
      action: PayloadAction<ActiveModalStates>
    ) => {
      state.value = action.payload;
    },
  },
});

export const { setActiveModal } = activeModalSlice.actions;

export const selectActiveModal = (state: AppState) => state.activeModal.value;
export const selectHasActiveModal = (state: AppState) => !!state.activeModal.value;

export default activeModalSlice.reducer;
