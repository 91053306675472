export interface PlanetData {
  color: string;
  diam: number;
  initDeg: number;
  name: string;
  orbit: number;
  period: number;
}

export const planetData: PlanetData[] = [
  {
    color: '#B1AE96',
    diam: 0.5,
    initDeg: 0,
    name: 'sol',
    orbit: 0,
    period: 0
  },
  {
    color: '#958E7E',
    diam: 0.034,
    initDeg: 350,
    name: 'mercury',
    orbit: 0.012,
    period: 87.9
  },
  {
    color: '#CBBDAC',
    diam: 0.084,
    initDeg: 31,
    name: 'venus',
    orbit: 0.024,
    period: 224.7
  },
  {
    color: '#94A0AF',
    diam: 0.089,
    initDeg: 292,
    name: 'earth',
    orbit: 0.033,
    period: 365.2
  },
  {
    color: '#A48B8B',
    diam: 0.048,
    initDeg: 185,
    name: 'mars',
    orbit: 0.051,
    period: 687.0
  },
  {
    color: '#CFCFAF',
    diam: 1,
    initDeg: 344,
    name: 'jupiter',
    orbit: 0.173,
    period: 4332.0
  },
  {
    color: '#DECDBC',
    diam: 0.843,
    initDeg: 14,
    name: 'saturn',
    orbit: 0.319,
    period: 10759.0
  },
  {
    color: '#9CB8A1',
    diam: 0.358,
    initDeg: 122,
    name: 'uranus',
    orbit: 0.639,
    period: 30688.0
  },
  {
    color: '#90A3AA',
    diam: 0.346,
    initDeg: 76,
    name: 'neptune',
    orbit: 1,
    period: 60182.0
  }
];
