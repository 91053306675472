import React, { useCallback, useMemo } from "react";
import { Lines } from "./lines";
import { ButtonProps } from "./types";
import { abbreviateNumber } from "utils/abbreviate-number";
import "./index.scss";
import { FieldValues } from "react-hook-form";

interface CounterProps<T extends FieldValues> extends ButtonProps<T> {
  count?: number;
  limit?: number;
  showLimit?: boolean;
}

export const Counter = <T extends FieldValues>({
  count = 0,
  limit = 0,
  showLimit = false,
}: CounterProps<T>) => {
  const formattedCount = useMemo(() => abbreviateNumber(count), [count]);
  const formattedLimit = useMemo(() => limit > 0 ? abbreviateNumber(limit): undefined, [limit]);
  const isOver = useMemo(() => limit > 0 && count > limit, [count, limit]);

  const getFontSize = useCallback((size: number) => {
    if (size === 3) return 'smaller';
    else if (size >= 4) return 'smallest';
    return undefined;
  }, []);

  return (
    <div className="ui wrapper counter">
      <Lines fontSize={getFontSize(formattedCount.length)} theme={isOver ? 'mars' : 'uranus'} value={formattedCount} />
      {formattedLimit && showLimit && (
        <>
          <Lines fontSize="smallest" theme="uranus" value="/" />
          <Lines fontSize={getFontSize(formattedLimit.length)} theme="uranus" value={formattedLimit} />
        </>
      )}
    </div>
  );
};
