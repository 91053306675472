export enum Direction {
  COLUMN = 'column',
  ROW = 'row',
}

export enum PostType {
  Image = '24008da9-7a79-473c-a76b-b2d4b0d1ed17',
  Pdf = '4dca5a89-8921-47a8-ae16-9d2da34629ea',
}

export enum ModalType {
  Post = 'post',
  MockPost = 'mock-post',
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

// Order is significant.
export enum MenuItemType {
  Sol = 'Sol',
  Home = 'Home',
  Journal = 'Journal',
  Publish = 'Publish',
  Login = 'Login',
}
