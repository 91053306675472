import classNames from "classnames";
import React, { PropsWithChildren, ReactElement, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PostService from "services/post/service";
import "./index.scss";
import { getStartEndOfMonth } from "utils/get-start-end-of-month";
import { POST_PAGE_SIZE } from "slices/post-slice";

interface AppContainerProps {
  debug?: boolean;
}

const AppContainer: React.FC<PropsWithChildren<AppContainerProps>> = ({
  children,
  debug
}): ReactElement => {
  const dispatch = useDispatch();
  // todo: fix rerenders
  useEffect(() => {
    (async () => {
      const { end, start } = getStartEndOfMonth();
      await PostService.getPosts({
        endTimeUtc: end.toUTCString(),
        page: 1,
        pageSize: POST_PAGE_SIZE,
        startTimeUtc: start.toUTCString(),
      }, dispatch);
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      await PostService.getMetadata({
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }, dispatch);
    })();
  }, [dispatch]);

  return (
    <div className={classNames('app-container', { debug })}>
      {children}
    </div>
  );
};

export default connect()(AppContainer);
