import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "app/store";

interface IsLoadingState {
  value: boolean;
}

const initialState: IsLoadingState = {
  value: false,
};

export const isLoadingSlice = createSlice({
  name: 'isLoading',
  initialState,
  reducers: {
    setIsLoading: (state: IsLoadingState, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

export const { setIsLoading } = isLoadingSlice.actions;

export const selectIsLoading = (state: AppState) => state.isLoading.value;

export default isLoadingSlice.reducer;
