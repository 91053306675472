import classNames from "classnames";
import Ui from "components/ui";
import React, { PropsWithChildren, ReactElement } from "react";
import { connect } from "react-redux";
import { useModal } from "../hooks";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import "./index.scss";
import LoadingIndicator from "components/loading-indicator";

interface BaseModalProps {
  menuItems?: ReactElement;
}

const BaseModal: React.FC<PropsWithChildren<BaseModalProps>> = ({
  children,
  menuItems: MenuItems,
}): ReactElement => {
  const { onClose, isLoading, type } = useModal();

  return (
    <div className="modal-wrapper">
      <div className="modal">
        <div className="modal-menu">
          <LoadingIndicator isLoading={isLoading} rounded />
          {MenuItems}
          <Ui.Button icon={CloseIcon} iconOnly onClick={onClose} />
        </div>
        <div className={classNames('content', type ?? '')}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default connect()(BaseModal);
