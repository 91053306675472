import { useCallback, useEffect, useMemo, useState } from "react";
import { DAYS_IN_MONTHS, DAYS_IN_WEEK, EMPTY_ENTRY, MIN_ROW_COUNT } from "./constants";
import { Direction, NavType, OnMonthYearSettle } from "./types";
import { calculateFirstWeekday, getCalendarDigits } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "slices/is-loading-slice";
import { PostedDate, selectMetadata } from "slices/post-slice";
import { DEFAULT_DEBOUNCE_MS } from "utils";

export const useCalendar = (onSettle?: OnMonthYearSettle) => {
  const metadata = useSelector(selectMetadata);
  const dispatch = useDispatch();
  const [visibleDate, setVisibleDate] = useState<Date>(new Date());
  const [calendarLines, setCalendarLines] = useState<string[][]>([[]]);

  const currDate = useMemo(() => new Date(), []);
  const currDayOfMonthDigits: string = getCalendarDigits(
    DAYS_IN_MONTHS[currDate.getMonth()],
    currDate.getDate() - 1
  );

  const isCurrMonth = useMemo(() => (
    currDate.getMonth() === visibleDate.getMonth() &&
    currDate.getFullYear() === visibleDate.getFullYear()
  ), [currDate, visibleDate]);

  const isFirstMonth = useMemo(() => {
    if (!metadata?.pastLimitTimeUtc) return false;
    const newDate = new Date(metadata?.pastLimitTimeUtc);
    return newDate.getMonth() === visibleDate.getMonth() &&
      newDate.getFullYear() === visibleDate.getFullYear()
  }, [metadata?.pastLimitTimeUtc, visibleDate]);

  const monthYear = useMemo(() => (
    new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(visibleDate)
  ), [visibleDate]);

  const navigate = useCallback((currentDate: Date, navType: NavType, direction?: Direction) => {
    const pastLimit = metadata ? new Date(metadata.pastLimitTimeUtc) : undefined;
    const futureLimit = metadata ? new Date(metadata.futureLimitTimeUtc) : undefined;
    const newDate = new Date(currentDate);
    switch (navType) {
      case NavType.Month:
        newDate.setMonth(currentDate.getMonth() + (direction?.valueOf() ?? 0));
        break;
      case NavType.Year:
        newDate.setFullYear(currentDate.getFullYear() + (direction?.valueOf() ?? 0));
        break;
      case NavType.Reset:
        // falls through
      default:
        setVisibleDate(new Date());
        return;
    }
    const getNewDateBound = () => {
      if (pastLimit && newDate < pastLimit) return pastLimit;
      if (futureLimit && newDate > futureLimit) return futureLimit;
      return newDate;
    };
    setVisibleDate(getNewDateBound());
  }, [metadata]);

  const isDay = useCallback((dayOfMonth: string) => (
    isCurrMonth && dayOfMonth === currDayOfMonthDigits
  ), [currDayOfMonthDigits, isCurrMonth]);

  const getPostCount = useCallback((day: number) => {
    const newDate = new Date(visibleDate);
    const postedDate = (metadata?.postedDates ?? []).find((p: PostedDate) => (
      p.year === newDate.getFullYear() && p.month === (newDate.getMonth() + 1) && p.day === day
    ));
    return Math.min(3, postedDate?.count ?? 0);
  }, [metadata?.postedDates, visibleDate]);

  useEffect(() => {
    const daysInMonth: number = DAYS_IN_MONTHS[visibleDate.getMonth()];
    const firstWeekday: number = calculateFirstWeekday(
      visibleDate.getDay(),
      visibleDate.getDate()
    );

    let lineIndex: number = 0;
    const lines: string[][] = [[]];

    for (let ii = 0; ii < firstWeekday; ii++) {
      lines[lineIndex].push(EMPTY_ENTRY);
    }

    let digitLimit = DAYS_IN_WEEK - firstWeekday;
    let digitCount = 0;
    do {
      for (let ii = 0; ii < digitLimit; ii++) {
        const digit = digitCount + ii;
        lines[lineIndex].push(getCalendarDigits(daysInMonth, digit));
      }

      digitCount += digitLimit;
      digitLimit = Math.min(DAYS_IN_WEEK, daysInMonth - digitCount);

      if (digitLimit !== 0) {
        lineIndex++;
        lines.push([]);
      }
    } while (digitCount < daysInMonth);

    if (lines.length < MIN_ROW_COUNT) {
      const addRowCount = MIN_ROW_COUNT - lines.length;
      const blankLine: string[] = [];

      for (let ii = 0; ii < DAYS_IN_WEEK; ii++) {
        blankLine.push(EMPTY_ENTRY);
      }

      for (let ii = 0; ii < addRowCount; ii++) {
        lines.unshift(blankLine);
      }
    }

    setCalendarLines(lines);
  }, [setCalendarLines, visibleDate]);

  useEffect(() => {
    if (!!onSettle) dispatch(setIsLoading(true));
    const timer = setTimeout(() => {
      onSettle?.({
        month: visibleDate.getMonth() + 1,
        year: visibleDate.getFullYear(),
      });
      dispatch(setIsLoading(false));
    }, DEFAULT_DEBOUNCE_MS);
    return () => {
      clearTimeout(timer);
      dispatch(setIsLoading(false));
    };
  }, [dispatch, onSettle, visibleDate]);

  return {
    calendarLines,
    getPostCount,
    isCurrMonth,
    isDay,
    isFirstMonth,
    monthYear,
    navigate,
    visibleDate,
  };
};
