import React, { ReactElement, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { MenuItemType } from "models";
import EmptyPage from "./empty-page";
import HomePage from "./home-page";
import JournalPage from "./journal-page";
import LoginPage from "./login-page";
import PublishPage from "./publish-page";
import { selectActiveMenuItem } from "slices/active-menu-item-slice";

const Content: React.FC = (): ReactElement => {
  const activeMenuItem = useSelector(selectActiveMenuItem);
  const getPage = useCallback(() => {
    switch (activeMenuItem) {
      case MenuItemType.Home:
        return <HomePage />;
      case MenuItemType.Journal:
        return <JournalPage />;
      case MenuItemType.Login:
        return <LoginPage />;
      case MenuItemType.Publish:
        return <PublishPage />;
      case MenuItemType.Sol:
        return <></>;
      default:
        return <EmptyPage />;
    }
  }, [activeMenuItem]);

  return getPage();
};

export default connect()(Content);
