import Ui from "components/ui";
import React, { ReactElement } from "react";
import BaseModal from "../base-modal";
import { usePostStagingModalContent } from "../hooks";
import { getDateString } from "utils";
import "./index.scss";

export const PostStagingModalContent: React.FC = (): ReactElement => {
  const { body, dataUrl, name } = usePostStagingModalContent();
  return (
    <BaseModal>
      {dataUrl ? (
        <img alt="post content" className="image show" src={dataUrl} />
      ) : (
        <div className="default"></div>
      )}
      <div className="text">
        <div className="header">
          <Ui.Lines alignEnd theme="uranus" nowrap value={getDateString(new Date())} />
          <Ui.Lines theme="uranus" value={name} />
        </div>
        <div className="body">
          <Ui.Lines value={body} />
        </div>
      </div>
    </BaseModal>
  );
};
