import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PostService from "services/post/service";
import { ModalType, PostType } from "models/enums";
import { convertImageToThumbnailBlob } from "./convert-image-to-thumbnail";
import { setActiveModal } from "slices/active-modal-slice";
import { selectIsLoading } from "slices/is-loading-slice";
import { selectAuthToken } from "slices/auth-token-slice";
import { FieldValues, useForm, useWatch } from "react-hook-form";

interface ImageData {
  image: string;
  thumbnail: string;
}

interface PostFormValues extends FieldValues {
  body: string;
  image: FileList;
  imageAlt: string;
  name: string;
  type: string;
}

const defaultImageData: ImageData = {
  image: '',
  thumbnail: '',
};

export const usePostForm = () => {
  const authToken = useSelector(selectAuthToken);
  const isLoading = useSelector(selectIsLoading);

  const dispatch = useDispatch();
  const [thumbnail, setThumbnail] = useState<Blob | undefined>();
  const [imageData, setImageData] = useState<ImageData>(defaultImageData);

  const { control, register, handleSubmit, reset } = useForm<PostFormValues>({
    defaultValues: {
      body: '',
      image: {} as FileList,
      imageAlt: '',
      name: '',
      type: '',
    },
    mode: 'onChange',
  });

  const body = useWatch({ control, name: 'body' });
  const name = useWatch({ control, name: 'name' });
  const image = useWatch({ control, name: 'image' });
  const imageAlt = useWatch({ control, name: 'imageAlt' });

  const isSubmissionDisabled = useMemo(() => !authToken || isLoading, [authToken, isLoading]);

  const readBlobAsData = useCallback((blob: Blob, type: 'image' | 'thumbnail') => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      setImageData((prevData) => ({
        ...prevData,
        [type]: (e.target?.result as string)?.split(',')[1]
      }));
    };
    reader.readAsDataURL(blob);
  }, []);

  const onFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const selectedFile = e.target?.files?.[0];
    if (!selectedFile) return;
    readBlobAsData(selectedFile, 'image');

    const convertedImage = await convertImageToThumbnailBlob(selectedFile);
    setThumbnail(convertedImage);
    readBlobAsData(convertedImage, 'thumbnail');
  }, [readBlobAsData]);

  const onPostClick = useCallback(() => {
    dispatch(setActiveModal({
      body,
      dataUrl: image[0] ? URL.createObjectURL(image[0]) : '',
      name,
      type: ModalType.MockPost,
    }));
  }, [body, dispatch, image, name]);

  const resetForm = useCallback(() => {
    reset();
    setThumbnail(undefined);
    setImageData(defaultImageData);
  }, [reset]);

  const onSubmit = useCallback(async (values: PostFormValues) => {
    PostService.create(
      {
        body: values.body,
        data: imageData.image,
        fileType: values.image[0]?.type ?? '',
        imageAlt: values.imageAlt,
        name: values.name,
        thumbnail: imageData.thumbnail,
        type: values.type as PostType,
      },
      dispatch,
    ).then(() => {
      resetForm();
    });
  }, [dispatch, imageData.image, imageData.thumbnail, resetForm]);

  return {
    control,
    image,
    imageAlt,
    isSubmissionDisabled,
    onFileChange,
    onPostClick,
    onSubmit: handleSubmit(onSubmit),
    register,
    thumbnail,
  };
}
