import classNames from "classnames";
import React, { PropsWithChildren, ReactElement } from "react";
import "./index.scss";

export const PAGE_CONTAINER_ID = 'page_container';

interface PageContainerProps extends PropsWithChildren {
  isSolActive: boolean;
}

const PageContainer: React.FC<PageContainerProps> = (props): ReactElement => {
  const { isSolActive } = props;

  return (
    <div
      className={classNames("col", "page-container", {
        "fit-content": isSolActive,
      })}
      id={PAGE_CONTAINER_ID}
    >
      {props.children}
    </div>
  );
};

export default PageContainer;
