import Ui from "components/ui";
import React, { ReactElement, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { ReactComponent as HomeIcon } from "./icons/home.svg";
import { ReactComponent as JournalIcon } from "./icons/journal.svg";
import { ReactComponent as LoginActiveIcon } from "./icons/login-active.svg";
import { ReactComponent as LoginInactiveIcon } from "./icons/login-inactive.svg";
import { ReactComponent as PublishIcon } from "./icons/publish.svg";
import { ReactComponent as SolIcon } from "./icons/sol.svg";
import { selectActiveMenuItem } from "slices/active-menu-item-slice";
import { selectAuthToken } from "slices/auth-token-slice";
import "./index.scss";
import { MenuItemType } from "models";

export interface MenuItemProps {
  forceIconOnly: boolean;
  item: MenuItemType;
  onClick: (clickedMenuItem: MenuItemType) => Promise<void>;
}

const MenuItem: React.FC<MenuItemProps> = ({ forceIconOnly, item, onClick }): ReactElement => {
  const activeMenuItem = useSelector(selectActiveMenuItem);
  const authToken = useSelector(selectAuthToken);
  const isMenuItemActive = useMemo(() => item === activeMenuItem, [activeMenuItem, item]);
  const isSolActive = useMemo(() => activeMenuItem === MenuItemType.Sol, [activeMenuItem]);

  const Icon = useMemo(() => {
    switch (item) {
      case MenuItemType.Home:
        return HomeIcon;
      case MenuItemType.Journal:
        return JournalIcon;
      case MenuItemType.Login:
        return !!authToken ? LoginActiveIcon : LoginInactiveIcon;
      case MenuItemType.Publish:
        return PublishIcon;
      case MenuItemType.Sol:
        return SolIcon;
    }
  }, [authToken, item]);

  const iconOnly = useMemo(() => (
    ((isMenuItemActive || isSolActive) && item !== MenuItemType.Sol)
    || (!!authToken && item === MenuItemType.Login)
    || forceIconOnly
  ), [authToken, forceIconOnly, isMenuItemActive, isSolActive, item]);

  return (
    <Ui.Button
      disabled={isMenuItemActive}
      icon={Icon}
      iconOnly={iconOnly}
      onClick={() => onClick(item)}
      value={item.toString()}
    />
  );
};

export default connect()(MenuItem);
