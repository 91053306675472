import { FPS_TARGET, SOL_DAYS_PER_REAL_SECONDS } from "../constants";
import { easeInOutQuint } from "../utils";
import { constructPlanets } from "../utils";
import { planetData } from "./data";
import Orbit from "./orbit";
import Planet from "./planet";
import { Point } from "./point";

const CENTER_PERCENT: Point = { x: 0.6, y: 0.6 };
const INIT_DATE = new Date(2019, 3, 1);
const DEFAULT_PX_PER_S = 100;

// Singleton
export default class SolDraw {
  private planets: Planet[];
  private solCenter: Point = { x: 0, y: 0 };
  private solTraverse?: {
    origin: Point,
    target: Point,
    timeElapsed: number,
    timeTotal: number,
    travel: Point,
  };
  private canvasHeight: number = 0;
  private canvasWidth: number = 0;

  // Singleton methods

  private static _instance: SolDraw;

  private constructor() {
    const daysSinceInitDate = ((new Date()).getTime() - INIT_DATE.getTime()) / (1000 * 60 * 60 * 24);
    this.planets = constructPlanets(daysSinceInitDate, planetData);
  }

  // Public methods

  public static get instance() {
    SolDraw._instance = SolDraw._instance ?? new SolDraw();
    return SolDraw._instance;
  }

  public draw = (ctx?: CanvasRenderingContext2D): void => {
    if (!ctx) return;

    this.drawOrbits(ctx);
    this.drawPlanets(ctx);
  };

  public getHoveredOverPlanet = (pointer?: Point): Planet | undefined => {
    if (!pointer) return undefined;

    return this.planets.find((planet: Planet) => {
      const r = planet.radiusPixels;
      const isInXRange =
        pointer.x >= planet.center.x - r && pointer.x <= planet.center.x + r;
      const isInYRange =
        pointer.y >= planet.center.y - r && pointer.y <= planet.center.y + r;

      return isInXRange && isInYRange;
    });
  };

  public initialize = (
    canvasHeight: number,
    canvasWidth: number,
    isSolActive: boolean,
  ): void => {
    this.setCanvasDimens(canvasHeight, canvasWidth);
    this.calculatePlanetDimens();

    this.solCenter = {
      x: Math.floor(canvasWidth * (isSolActive ? 0.5 : CENTER_PERCENT.x) * 1000) / 1000,
      y: Math.floor(canvasHeight * (isSolActive ? 0.5 : CENTER_PERCENT.y) * 1000) / 1000,
    };
  };

  public onCanvasResize = (
    canvasHeight: number,
    canvasWidth: number,
    isSolActive: boolean,
  ): void => {
    this.initialize(canvasHeight, canvasWidth, isSolActive);
    this.calculateSolTraversal(isSolActive, 0);
  };

  public resetDaysSinceInit = (timer: number) => {
    const daysSinceInitDate = (timer - INIT_DATE.getTime()) / (1000 * 60 * 60 * 24);
    this.planets.forEach((p) => {
      p.resetRad(daysSinceInitDate);
    });
  }

  public updateCenterAndPosition = (frames: number): void => {
    this.updateCenter(frames);
    this.updatePosition(frames);
  };

  public updateCenterTarget = (isSolActive: boolean): void => {
    this.calculateSolTraversal(isSolActive, 1200);
  };

  // Private Methods

  private calculatePlanetDimens = (): void => {
    this.planets.forEach((planet: Planet) => {
      planet.setRadiusPixels(this.canvasHeight);
    });

    let orbitRadiusMin = 0;
    this.planets.forEach((planet: Planet) => {
      const orbit = planet.orbit;
      if (!!orbit) {
        orbitRadiusMin += planet.radiusPixels + Planet.PLANET_PADDING;
        orbit.setOrbitRadiusPixels(this.canvasHeight, orbitRadiusMin);
      }

      planet.setCenter(this.solCenter);
      orbitRadiusMin =
        (orbit ? orbit.orbitRadiusPixels : 20) + planet.radiusPixels;
    });
  };
  
  private calculateSolTraversal = (isSolActive: boolean, animMilliseconds?: number): void => {
    const target: Point = {
      x: Math.floor(this.canvasWidth * (isSolActive ? 0.5 : CENTER_PERCENT.x) * 1000) / 1000,
      y: Math.floor(this.canvasHeight * (isSolActive ? 0.5 : CENTER_PERCENT.y) * 1000) / 1000,
    };
    
    const xTravel = target.x - this.solCenter.x;
    const yTravel = target.y - this.solCenter.y;
    const timeTotal = animMilliseconds ? animMilliseconds / 1000 : Math.sqrt(xTravel ** 2 + yTravel ** 2) / DEFAULT_PX_PER_S;

    this.solTraverse = {
      origin: this.solCenter,
      target,
      timeElapsed: 0,
      timeTotal,
      travel: {
        x: Math.floor(xTravel * 1000) / 1000,
        y: Math.floor(yTravel * 1000) / 1000,
      },
    }
  };

  private drawOrbits = (ctx: CanvasRenderingContext2D): void => {
    this.planets
      .map((planet: Planet) => planet.orbit)
      .forEach((orbit?: Orbit) => {
        if (orbit !== undefined) {
          ctx.save();

          ctx.beginPath();
          ctx.arc(
            this.solCenter.x,
            this.solCenter.y,
            orbit.orbitRadiusPixels,
            0,
            2 * Math.PI
          );

          ctx.strokeStyle = orbit.color;
          ctx.lineWidth = orbit.orbitThickness();
          ctx.stroke();

          ctx.restore();
        }
      });
  };

  private drawPlanets = (ctx: CanvasRenderingContext2D): void => {
    this.planets.forEach((planet: Planet) => {
      ctx.save();

      ctx.beginPath();
      ctx.arc(
        planet.center.x,
        planet.center.y,
        planet.radiusPixels,
        0,
        2 * Math.PI
      );

      ctx.fillStyle = planet.color;
      ctx.fill();

      ctx.strokeStyle = Planet.OUTLINE_COLOR;
      ctx.lineWidth = Planet.OUTLINE_THICKNESS;
      ctx.stroke();

      ctx.restore();
    });
  };

  private setCanvasDimens = (height: number, width: number): void => {
    this.canvasHeight = height;
    this.canvasWidth = width;
  };

  private updateCenter = (frames: number): void => {
    if (!this.solTraverse || this.solTraverse.timeTotal <= 0 || this.solTraverse.timeElapsed >= this.solTraverse.timeTotal) {
      this.solCenter = this.solTraverse?.target ?? this.solCenter;
      this.solTraverse = undefined;
      return;
    }
    
    const secondsToUpdate = frames / FPS_TARGET;
    const easeMultiplier = easeInOutQuint((this.solTraverse.timeElapsed + secondsToUpdate) / this.solTraverse.timeTotal);

    this.solCenter = {
      x: Math.floor((this.solTraverse.origin.x + easeMultiplier * this.solTraverse.travel.x) * 1000) / 1000,
      y: Math.floor((this.solTraverse.origin.y + easeMultiplier * this.solTraverse.travel.y) * 1000) / 1000,
    };

    this.solTraverse.timeElapsed += secondsToUpdate;
  };

  private updatePosition = (frames: number): void => {
    this.planets.forEach((planet: Planet) => {
      planet.setCenter(this.solCenter);

      const orbit = planet.orbit;
      if (!orbit) return;   

      const radsPerS = orbit.radsPerDay * SOL_DAYS_PER_REAL_SECONDS;
      const radsPerFrame = radsPerS / FPS_TARGET;

      planet.addRad(frames * radsPerFrame);
    });
  };
}
