import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "app/store";

interface SolState {
  currentTime: number;
  displayTime: number;
  isPaused: boolean;
  isPrinting: boolean;
}

const initialState: SolState = {
  currentTime: (new Date()).getTime(),
  displayTime: (new Date()).getTime(),
  isPaused: false,
  isPrinting: false,
};

export const solSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    addDisplayTime: (state: SolState, action: PayloadAction<number>) => {
      state.displayTime += action.payload;
    },
    resetDisplayTime: (state: SolState) => {
      state.displayTime = state.currentTime;
    },
    setIsPaused: (state: SolState, action: PayloadAction<boolean>) => {
      state.isPaused = action.payload;
    },
    setIsPrinting: (state: SolState, action: PayloadAction<boolean>) => {
      state.isPrinting = action.payload;
    },
  },
});

export const { addDisplayTime, resetDisplayTime, setIsPaused, setIsPrinting } = solSlice.actions;

export const selectCurrentTime = ({ sol }: AppState) => sol.currentTime;
export const selectDisplayTime = ({ sol }: AppState) => sol.displayTime;
export const selectIsPaused = ({ sol }: AppState) => sol.isPaused;
export const selectIsPrinting = ({ sol }: AppState) => sol.isPrinting;

export default solSlice.reducer;
