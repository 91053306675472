import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import activeMenuItemReducer from "slices/active-menu-item-slice";
import activeModalReducer from "slices/active-modal-slice";
import authTokenReducer from "slices/auth-token-slice";
import isLoadingReducer from "slices/is-loading-slice";
import notifierMessageReducer from "slices/notifier-message-slice";
import postReducer from "slices/post-slice";
import solReducer from "slices/sol-slice";

export const store = configureStore({
  reducer: {
    activeMenuItem: activeMenuItemReducer,
    activeModal: activeModalReducer,
    authToken: authTokenReducer,
    isLoading: isLoadingReducer,
    notifierMessage: notifierMessageReducer,
    posts: postReducer,
    sol: solReducer,
  },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
