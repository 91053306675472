
import classNames from "classnames";
import React, { ReactElement, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsLoading } from "slices/is-loading-slice";

interface CardProps {
  entityId: string;
  imgAlt: string;
  imgBlob?: Blob | null;
  imgUrl?: string;
  label: string;
  onClick: (entityId: string) => void;
}

export const Card: React.FC<CardProps> = ({
  entityId,
  imgAlt,
  imgBlob,
  imgUrl,
  label,
  onClick,
}): ReactElement => {
  const url = useMemo(() => (imgBlob && URL.createObjectURL(imgBlob)) || imgUrl || undefined, [imgBlob, imgUrl]);
  const [hasImageLoaded, setHasImageLoaded] = useState<boolean>(false);
  return (
    <div className="ui wrapper card" onClick={() => onClick(entityId)}>
      {url ? (
        <img
          alt={imgAlt}
          className={classNames('image', hasImageLoaded && 'show')}
          onLoad={() => setHasImageLoaded(true)}
          src={url}
        />
      ) : (
        <div className="default" />
      )}
      <span className="ui core card-label">{label}</span>
    </div>
  );
}

export const BlankCard: React.FC = (): ReactElement => {
  const isLoading = useSelector(selectIsLoading);
  return (
    <div className={classNames('ui wrapper card blank', isLoading && 'loading')}>
      <div className="default" />
      <span className="ui core card-label" />
    </div>
  );
}
