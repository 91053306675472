import React, { FC } from "react";
import { connect } from "react-redux";
import { ReactComponent as FastForwardIcon } from "./icons/ff.svg";
import { ReactComponent as FastFastForwardIcon } from "./icons/fff.svg";
import { ReactComponent as MoreIcon } from "./icons/more.svg";
import { ReactComponent as PauseIcon } from "./icons/pause.svg";
import { ReactComponent as PlayIcon } from "./icons/play.svg";
import { ReactComponent as RevertIcon } from "./icons/revert.svg";
import { ReactComponent as ScreenshotIcon } from "./icons/screenshot.svg";
import Ui from "components/ui";
import { getDateString } from "utils";
import { useControl } from "./hooks";
import "./index.scss";

const FAST_FASTFORWARD_DAYS = 364;
const FASTFORWARD_DAYS = 28;

const SolControl: FC = () => {
  const {
    addAnimationDisplayDate,
    displayDate,
    isPaused,
    isSolActive,
    resetAnimationDisplayDate,
    takeScreenshot,
    togglePauseAnimation,
  } = useControl();
  return (
    <div className="sol-control">
      {/* more */}
      {!isSolActive && (
        <Ui.Button
          disabled={!isSolActive}
          icon={MoreIcon}
          iconOnly
        />
      )}
      {isSolActive && (
        <>
          <Ui.Button
            disabled={!isSolActive}
            icon={ScreenshotIcon}
            iconOnly
            onClick={takeScreenshot}
          />
          <Ui.Button
            disabled={!isSolActive}
            icon={RevertIcon}
            iconOnly
            onClick={resetAnimationDisplayDate}
            value="reset"
          />
          <Ui.Button
            disabled={!isSolActive}
            icon={isPaused ? PlayIcon : PauseIcon}
            iconOnly
            onClick={() => togglePauseAnimation(!isPaused)}
            value="play/pause"
          />
          <Ui.Button
            disabled={!isSolActive}
            icon={FastForwardIcon}
            iconOnly
            onClick={() => {
              addAnimationDisplayDate(FASTFORWARD_DAYS * 24 * 60 * 60 * 1000);
            }}
            value="fast-forward"
          />
          <Ui.Button
            disabled={!isSolActive}
            icon={FastFastForwardIcon}
            iconOnly
            onClick={() => {
              addAnimationDisplayDate(FAST_FASTFORWARD_DAYS * 24 * 60 * 60 * 1000);
            }}
            value="fast fast-forward"
          />
        </>
      )}
      <Ui.Lines
        nowrap={true}
        value={getDateString(displayDate || new Date())}
      />
    </div>
  );
}

export default connect()(SolControl);