import { clearToken, setAuthToken } from "slices/auth-token-slice";
import { Communicate, Configuration } from "utils";
import { Dispatch } from "react";
import { setIsLoading } from "slices/is-loading-slice";
import { setError, setMessages, setSuccess } from "slices/notifier-message-slice";
import { setActiveMenuItem } from "slices/active-menu-item-slice";
import { MenuItemType } from "models";
import { BaseRequest, BaseResponse } from "utils/communicate";

interface LoginData extends BaseResponse {
  needsUpdate: boolean;
  token: string;
}

interface LoginRequest extends BaseRequest {
  email: string;
  password: string;
}

const base = `${Configuration.apiUri}/users`;

class UserService {
  public static async login(request: LoginRequest, dispatch: Dispatch<any>) {
    try {
      dispatch(setIsLoading(true));
      const { data } = await Communicate.post<LoginData>(
        `${base}/login`,
        request
      );
      dispatch(setAuthToken(data.token));
      dispatch(setSuccess('Success.'));
      dispatch(setActiveMenuItem(MenuItemType.Home));
    } catch (m) {
      dispatch(setError(m as string[]));
    } finally {
      dispatch(setIsLoading(false));
    }
  }

  public static async logout(dispatch: Dispatch<any>) {
    try {
      dispatch(setIsLoading(true));
      dispatch(clearToken());
      dispatch(setMessages('Logged out.'));
    } catch (m) {
      dispatch(setError((m as string[])));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
}

export default UserService;
