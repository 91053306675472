import React, { useEffect, useState } from "react";
import { Input } from "./input";
import { Lines } from "./lines";
import { InputProps } from "./types";
import { FieldValues } from "react-hook-form";

interface FileProps<T extends FieldValues> extends InputProps<T> {
  hasImage?: boolean;
  id: string;
}

const defaultFileName = '...';

export const File = <T extends FieldValues>({
  hasImage,
  options,
  ...rest
}: FileProps<T>) => {
  const [fileName, setFileName] = useState<string>(defaultFileName);
  useEffect(() => {
    if (!hasImage) setFileName(defaultFileName);
  }, [hasImage]);
  return (
    <div className="ui wrapper file">
      <label htmlFor={rest.id} className="ui core label-file">
        Select file
      </label>
      <Input
        {...rest}
        options={{
          ...options,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setFileName(
              !!e.target.files && e.target.files.length > 0
                ? `Selected: ${e.target.files[0].name}`
                : defaultFileName
            );
            options?.onChange?.(e);
          },
        }}
        type="file"
      />
      <Lines nowrap value={fileName} />
    </div>
  );
};
