import { MenuItemType } from "models";
import { CANVAS_WRAPPER_ID } from "components/sol/constants";
import { toPng } from "html-to-image";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux"
import { selectActiveMenuItem } from "slices/active-menu-item-slice";
import { addDisplayTime, resetDisplayTime, selectDisplayTime, selectIsPaused, selectIsPrinting, setIsPaused, setIsPrinting } from "slices/sol-slice";
import { getDateString } from "utils";

export const useControl = () => {
  const activeMenuItem = useSelector(selectActiveMenuItem);
  const displayTime = useSelector(selectDisplayTime);
  const isPaused = useSelector(selectIsPaused);
  const isPrinting = useSelector(selectIsPrinting);
  const dispatch = useDispatch();

  const displayDate = useMemo(() => new Date(displayTime), [displayTime]);
  const isSolActive = useMemo(() => activeMenuItem === MenuItemType.Sol, [activeMenuItem]);

  const addAnimationDisplayDate = useCallback((time: number): void => {
    dispatch(addDisplayTime(time));
    dispatch(setIsPaused(true));
  }, [dispatch]);

  const resetAnimationDisplayDate = useCallback((): void => {
    dispatch(resetDisplayTime());
    dispatch(setIsPaused(true));
  }, [dispatch]);

  const takeScreenshot = useCallback(async () => {
    const canvas = (document.getElementById(CANVAS_WRAPPER_ID) as HTMLCanvasElement) ?? null;
    if (!canvas) return;

    const dataUrl = await toPng(canvas);
    const downloadElement = window.document.createElement('a');

    downloadElement.href = dataUrl;
    downloadElement.download = `sol_${getDateString(displayDate || new Date())}.png`;
    downloadElement.style.display = 'none';
    (document.body || document.documentElement)?.appendChild(downloadElement);

    if (typeof downloadElement.click === 'function') {
      downloadElement.click();
    } else {
      downloadElement.target = '_blank';
      downloadElement.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }

    dispatch(setIsPrinting(true));
    URL.revokeObjectURL(downloadElement.href);
    downloadElement.remove();
  }, [dispatch, displayDate]);

  const togglePauseAnimation = useCallback((pause: boolean): void => {
    dispatch(setIsPaused(pause));
    }, [dispatch]);

  useEffect(() => {
    if(!isSolActive) {
      dispatch(setIsPaused(false));
    }
  }, [dispatch, isSolActive]);

  useEffect(() => {
    if (!isPrinting) return;

    const timer = setTimeout(() => {
      dispatch(setIsPrinting(false));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, isPrinting]);

  return {
    addAnimationDisplayDate,
    displayDate,
    isPaused,
    isSolActive,
    resetAnimationDisplayDate,
    takeScreenshot,
    togglePauseAnimation,
  }
}
