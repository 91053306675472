import classNames from "classnames";
import React, { useMemo } from "react";
import { Input } from "./input";
import { ButtonProps } from "./types";
import { FieldValues } from "react-hook-form";

export const Button = <T extends FieldValues>({
  icon: Icon,
  iconAlignEnd,
  iconOnly,
  value,
  ...passedProps
}: ButtonProps<T>) => {
  const props = useMemo(
    () => ({
    ...passedProps,
      value: iconOnly ? '' : value,
    }),
    [iconOnly, passedProps, value]
  );

  return (
    <div className={classNames('ui wrapper button', {
      'icon-only': iconOnly,
    })}>
      {/* Using disabled classname instead of prop for compatibility */}
      {!!Icon && <Icon className={classNames('ui icon embed', {
        'align-end': iconAlignEnd,
        disabled: props.disabled,
      })} />}
      <Input
        {...props}
        hasIcon={!!Icon}
        type="button"
      />
    </div>
  );
};
