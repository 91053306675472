import { RefObject, useEffect } from "react";

function useClickOutside<T extends HTMLElement>(
  ref: RefObject<T | null> | null,
  onClick: () => void
) {
  const event = "mousedown";
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (onClick && !ref?.current?.contains(e.target as T)) {
        onClick();
      }
    };

    document.addEventListener(event, handleClickOutside);

    return () => {
      document.removeEventListener(event, handleClickOutside);
    };
  }, [ref, onClick]);
}

export default useClickOutside;
