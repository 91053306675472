import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearMessages, selectNotifierMessages } from "slices/notifier-message-slice";

const DURATION_PER_MESSAGE_MS = 1500;
const MESSAGE_DELAY_MS = 100;

export const useNotifier = () => {
    const dispatch = useDispatch();
    const messages = useSelector(selectNotifierMessages);
  
    const messagesCount = !!messages ? messages.length : 0;
    const animationDurationValue =
      (DURATION_PER_MESSAGE_MS * messagesCount +
        (messagesCount - 1) * MESSAGE_DELAY_MS) /
      2;

    const calculateAnimDelay = (delay: number) => `${delay * MESSAGE_DELAY_MS}ms`;
  
    useEffect(() => {
      const timeout = setTimeout(() => {
        dispatch(clearMessages());
      }, animationDurationValue * 2);
  
      return () => {
        clearTimeout(timeout);
      };
    }, [animationDurationValue, dispatch]);

    return {
        calculateAnimDelay,
        animationDuration: `${animationDurationValue}ms`,
        messages,
    };
}
