export const DEFAULT_DEBOUNCE_MS = 1200;
export const FIELDS = {
    MAX: { 
        IMAGE_ALT: 255,
        NAME: 50,
    },
    MIN: {
        BODY: 1,
        IMAGE_ALT: 3,
        NAME: 3,
    },
};
