import React, { PropsWithChildren } from "react";
import { Input } from "./input";
import { InputProps } from "./types";
import { FieldValues, Path, RegisterOptions, UseFormRegister } from "react-hook-form";

interface TextBaseProps {
  max?: number;
  min?: number;
}

const TextBase = ({
  children,
  max = 0,
  min = 0,
}: PropsWithChildren<TextBaseProps>) => (
  <div className="ui wrapper text">
    {children}
    <div className="bottom-border" />
    {(max > 0 || min > 0) && (
      <div className="min-max">
        {[!!min && `min: ${min}`, !!max && `max: ${max}`].filter(v => v).join(' | ')}
      </div>
    )}
  </div>
);

export const Password = <T extends FieldValues>(props: InputProps<T>) => (
  <TextBase max={props.options?.maxLength as number} min={props.options?.minLength as number}>
    <Input
      {...props}
      type="password"
    />
  </TextBase>
);

export const Text = <T extends FieldValues>(props: InputProps<T>) => (
  <TextBase max={props.options?.maxLength as number} min={props.options?.minLength as number}>
    <Input
      {...props}
      type="text"
    />
  </TextBase>
);

interface TextAreaProps<T extends FieldValues>
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: Path<T>;
    register?: UseFormRegister<T>;
    options?: RegisterOptions<T>;
  }

export const TextArea = <T extends FieldValues>({
  label,
  options,
  register,
  ...rest
}: TextAreaProps<T>) => (
  <TextBase max={options?.maxLength as number} min={options?.minLength as number}>
    <textarea
      className="ui core text-area"
      {...rest}
      {...register && label && register(label, options)}
    />
  </TextBase>
);
