import { BaseResponse } from "./types";

export const parseErrors = (e: unknown): string | string[] => {
  const errorData = (e as any)?.response?.data as BaseResponse ?? null;
  if (!errorData) {
    return 'Something went wrong.';
  }

  const { errors, message } = errorData;
  const errorsArray = Object.values(errors ?? {})
    .reduce((acc: string[], e: string[]) => [...acc, ...e], [] as string[]);
  return [
    ...errorsArray,
    ...(message ? [ message ] : []), 
  ];
}
