import Ui from "components/ui";
import React, { ReactElement } from "react";
import { connect } from "react-redux";
import { Order } from "models/enums";
import { useNotifier } from "./hooks";
import "./index.scss";
import { NotifierMessage } from "slices/notifier-message-slice";
import classNames from "classnames";

interface NotifierProps {
  appearBy?: Order;
}

const Notifier: React.FC<NotifierProps> = ({ appearBy = Order.DESC }): ReactElement => {
  const {
    calculateAnimDelay,
    animationDuration,
    messages,
   } = useNotifier();
   
  return (
    <div className="notifier-list">
      {messages.map((nm: NotifierMessage, ii: number, arr: NotifierMessage[]) => {
        const appearByDelay = appearBy === "DESC" ? arr.length - 1 - ii : ii;
        const color = (() => {
          switch(nm.flavor) {
            case 'error':
              return 'mars';
            case 'success':
              return 'uranus';
            case 'default':
              return undefined;
          }
        })();
        return (
          <div
            className={classNames('notifier-item', nm.flavor)}
            key={ii}
            style={{
              animationDelay: calculateAnimDelay(appearByDelay),
              animationDuration,
            }}
          >
            <Ui.Lines theme={color} nowrap value={nm.message} />
          </div>
        );
      })}
    </div>
  );
};

export default connect()(Notifier);
