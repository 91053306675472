import React from 'react';
import { Button } from "./button";
import { BlankCard, Card } from './card';
import { File } from "./file";
import { Header } from "./header";
import { Input } from "./input";
import { Lines } from "./lines";
import { Select } from "./select";
import { Password, Text, TextArea } from "./text";
import { Counter } from './counter';
import { InputProps } from './types';
import { FieldValues } from 'react-hook-form';

const Ui = {
  Button,
  BlankCard,
  Card,
  Counter,
  File,
  Header,
  Lines,
  Password,
  Select,
  Submit: <T extends FieldValues>(props: InputProps<T>) => (
    <Input
      {...props}
      type="submit"
      value="Submit"
    />
  ),
  Text,
  TextArea,
};

export default Ui;
