import { ModalType } from "models";
import React, { ReactElement } from "react";
import { connect } from "react-redux";
import { useModal } from "./hooks";
import { PostStagingModalContent, PostModalContent } from "./post-modal-content";


const Modal = (): ReactElement => {
  const { type } = useModal();

  if (!type) return <></>;

  const getModal = () => {
    switch (type) {
      case ModalType.Post:
        return <PostModalContent />;
      case ModalType.MockPost:
        return <PostStagingModalContent />;
      default:
        return <></>;
    }
  };

  return getModal();
};

export default connect()(Modal);
