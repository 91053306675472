import classNames from "classnames";
import React from "react";
import { InputProps } from "./types";
import { FieldValues } from "react-hook-form";

export const Input = <T extends FieldValues>({
  disabled,
  hasIcon,
  label,
  options,
  register,
  theme,
  type,
  ...rest
}: InputProps<T>) => (
  <input
    className={classNames(
      'ui core',
      theme,
      type,
      {
        disabled: !!disabled,
        icon: hasIcon,
        'icon-only': hasIcon && !rest.value,
      }
    )}
    {...{ disabled, type, ...rest }}
    {...register && label && register(label, options)}
  />
);
