import classNames from "classnames";
import React, { PropsWithChildren, ReactElement } from "react";
import { Direction } from "models/enums";
import "./index.scss";

interface SectionProps extends PropsWithChildren {
  centered?: boolean;
  direction?: Direction;
}

const Section: React.FC<SectionProps> = ({
  centered,
  children,
  direction = Direction.COLUMN,
}): ReactElement => (
  <div className={
    classNames('section', direction, { centered: centered })
  }>
    {children}
  </div>
);

export default Section;
