import React, { ComponentType } from "react";

interface HeaderProps {
  icon?: ComponentType<any>;
  id?: string;
  value: string;
}

export const Header: React.FC<HeaderProps> = ({
  icon: Icon,
  id,
  value,
}) => (
  <div id={id} className="ui wrapper header">
    <span className="ui core header">
      {value}
      {!!Icon && <Icon className="ui icon embed" />}
    </span>
  </div>
);
