import classNames from "classnames";
import Ui from "components/ui";
import React, { ReactElement } from "react";
import { connect } from "react-redux";
import { useCalendar } from "./hooks";
import { ReactComponent as NextMonthIcon } from "./icons/next-month.svg";
import { ReactComponent as PrevMonthIcon } from "./icons/prev-month.svg";
import { ReactComponent as PrevYearIcon } from "./icons/prev-year.svg";
import { ReactComponent as NextYearIcon } from "./icons/next-year.svg";
import { ReactComponent as ResetIcon } from "./icons/reset.svg";
import "./index.scss";
import { Direction, NavType, OnMonthYearSettle } from "./types";
import { WEEKDAYS } from "./constants";

interface CalendarProps {
  onSettle?: OnMonthYearSettle;
}

const Calendar: React.FC<CalendarProps> = ({
  onSettle,
}): ReactElement => {
  const {
    calendarLines,
    getPostCount,
    isCurrMonth,
    isDay,
    isFirstMonth,
    monthYear,
    navigate,
    visibleDate,
  } = useCalendar(onSettle);
  return (
    <div className="calendar">
      <div className="display">
        <span className="month-year">
          {monthYear}
        </span>
        <div className="days">
          <div className="row">
            {WEEKDAYS.map((abbr: string, ii: number) => (
              <span className="weekday" key={ii}>
                {abbr}
              </span>
            ))}
          </div>
          {calendarLines.map((line: string[], ii: number) => (
            <div className="row" key={ii}>
              {line.map((dayOfMonth: string, jj: number) => {
                const postedCount = getPostCount(parseInt(dayOfMonth, 10));
                return (
                  <div className="day-wrapper" key={`${dayOfMonth}-${jj}`}>
                    <span
                      className={classNames('day', {
                        selected: isDay(dayOfMonth),
                      })}
                    >
                      {dayOfMonth}
                    </span>
                    {postedCount > 0 && (
                      <div className="posted-dot-wrapper" >
                        {Array.from({ length: postedCount }).map((_, kk: number) => (
                          <div key={kk} className="posted-dot"/>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
      <div className="controls">
        <Ui.Button
          disabled={isFirstMonth}
          icon={PrevYearIcon}
          onClick={() => navigate(visibleDate, NavType.Year, Direction.Backwards)}
          iconOnly
        />
        <Ui.Button
          disabled={isFirstMonth}
          icon={PrevMonthIcon}
          onClick={() => navigate(visibleDate, NavType.Month, Direction.Backwards)}
          iconOnly
        />
        <Ui.Button
          disabled={isCurrMonth}
          icon={ResetIcon}
          onClick={() => navigate(visibleDate, NavType.Reset)}
          iconOnly
        />
        <Ui.Button
          disabled={isCurrMonth}
          icon={NextMonthIcon}
          onClick={() => navigate(visibleDate, NavType.Month, Direction.Forwards)}
          iconOnly
        />
        <Ui.Button
          disabled={isCurrMonth}
          icon={NextYearIcon}
          onClick={() => navigate(visibleDate, NavType.Year, Direction.Forwards)}
          iconOnly
        />
      </div>
    </div>
  );
};

export default connect()(Calendar);
