import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "app/store";
import { HighlightedPost, Post } from "models";
import { chain, uniqBy } from 'lodash';

export const POST_PAGE_SIZE = 8;

export interface PostedDate {
  year: number;
  month: number;
  day: number;
  count: number;
}

interface PostMetadata {
  postedDates: PostedDate[],
  futureLimitTimeUtc: string,
  pastLimitTimeUtc: string,
};

export interface PagePostMetadataState {
  loaded: boolean;
  list: Post[];
  total: number;
}

export interface PagePostState {
  [page: number]: PagePostMetadataState;
}

interface MonthPostState {
  [month: number]: PagePostState;
}

interface YearPostState {
  [year: number]: MonthPostState;
}

interface PostState {
  data: YearPostState;
  metadata?: PostMetadata;
  pinned: HighlightedPost[];
  popular: HighlightedPost[];
}

const initialState: PostState = {
  data: {},
  pinned: [],
  popular: [],
};

export const postSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPosts: (state: PostState, action: PayloadAction<{ month: number, year: number, page: number, posts: Post[], total: number }>) => {
      const currState = state.data;
      state.data = {
        ...currState,
        [action.payload.year]: {
          ...currState[action.payload.year],
          [action.payload.month]: {
            ...currState[action.payload.year]?.[action.payload.month],
            [action.payload.page]: {
              loaded: true,
              list: action.payload.posts,
              total: action.payload.total,
            },
          },
        },
      };
    },
    setHighlights: (state: PostState, action: PayloadAction<{ pinned: HighlightedPost[], popular: HighlightedPost[] }>) => {
      state.pinned = action.payload.pinned;
      state.popular = action.payload.popular;
    },
    setMetadata: (state: PostState, action: PayloadAction<PostMetadata>) => {
      state.metadata = action.payload;
    },
    updatePost: (state: PostState, action: PayloadAction<Post>) => {
      const year = new Date(action.payload.createdAt).getFullYear();
      const month = new Date(action.payload.createdAt).getMonth() + 1;
      const total = state.data[year]?.[month]?.[1]?.total ?? 0;
      const monthYearPosts = Object.values({...(state.data[year]?.[month] ?? {
        1: {
          loaded: true,
          list: [],
          total: 0,
        }
      })})
          .reduce((acc: Post[], curr: PagePostMetadataState) => [...acc, ...curr.list], [action.payload])
          .map((p: Post) => p.id === action.payload.id ? action.payload : p);
      const uniqueMonthYearPosts = uniqBy(monthYearPosts, 'id');
      const monthPosts = chain(uniqueMonthYearPosts)
        .orderBy('createdAt', 'desc')
        .chunk(POST_PAGE_SIZE)
        .value()
        .reduce((acc: PagePostState, curr: Post[], ii: number) => ({
          ...acc,
          [ii + 1]: {
            loaded: true,
            list: curr,
            total,
          } as PagePostMetadataState,
        }), {} as PagePostState);
      const currState = state.data;
      state.data = {
        ...currState,
        [year]: {
          ...currState[year],
          [month]: monthPosts,
        },
      };
    },
  },
});

export const { setPosts, setHighlights, setMetadata, updatePost } = postSlice.actions;

export const selectPinned = ({ posts }: AppState) => posts.pinned;
export const selectPopular = ({ posts }: AppState) => posts.popular;
export const selectPosts = ({ posts }: AppState) => posts.data;
export const selectMetadata = ({ posts }: AppState) => posts.metadata;

export default postSlice.reducer;
