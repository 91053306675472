import Section from "components/section";
import Ui from "components/ui";
import React, { ReactElement } from "react";
import { connect } from "react-redux";
import { Direction, PostType } from "models/enums";
import { FIELDS, getDateString } from "utils";
import BasePage from "../base-page";
import { usePostForm } from "./hooks";

const selectItems = Object.entries(PostType).map(([key, value]) => ({ key, value }));

const PublishPage: React.FC = (): ReactElement => {
  const {
    control,
    image,
    imageAlt,
    isSubmissionDisabled,
    onFileChange,
    onPostClick,
    onSubmit,
    register,
    thumbnail,
  } = usePostForm();
  return (
    <BasePage>
      <form {...{ onSubmit }}>
        <Section centered>
          <Ui.Text
            label="name"
            register={register}
            options={{
              maxLength: FIELDS.MAX.NAME,
              minLength: FIELDS.MIN.NAME,
              required: true,
            }}
            placeholder="Name your post."
          />
          <Ui.TextArea
            label="body"
            register={register}
            options={{
              minLength: FIELDS.MIN.BODY,
              required: true,
            }}
            placeholder="Describe your post."
          />
          <Ui.Select
            control={control}
            items={selectItems}
            label="type"
            rules={{
              required: true,
              validate: (value: string) => (
                !!selectItems.find((i: { value: PostType }) => value === i.value)
              ),
            }}
          />
          <Ui.File
            hasImage={!!image.length}
            id="post_image"
            label="image"
            options={{
              onChange: onFileChange,
              required: true,
            }}
            register={register}
          />
          <Ui.Text
            label="imageAlt"
            register={register}
            options={{
              maxLength: FIELDS.MAX.IMAGE_ALT,
              minLength: FIELDS.MIN.IMAGE_ALT,
              required: true,
            }}
            placeholder="Describe your image."
          />
        </Section>
        <Section centered direction={Direction.ROW} >
          <Ui.Card
            entityId="new-id"
            imgAlt={imageAlt}
            imgBlob={thumbnail}
            label={getDateString(new Date())}
            onClick={onPostClick}
          />
        </Section>
        <Section centered>  
          <Ui.Submit disabled={isSubmissionDisabled} />
        </Section>
      </form>
    </BasePage>
  );
};

export default connect()(PublishPage);
