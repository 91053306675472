import Modal from "components/modal";
import Sol from "components/sol";
import React from "react";
import { connect, useSelector } from "react-redux";
import AppContainer from "./app-container";
import ContentContainer from "./content-container";
import { selectHasActiveModal } from "slices/active-modal-slice";

const App: React.FC = () => {
  const hasActiveModal = useSelector(selectHasActiveModal);
  return (
    <AppContainer>
      {hasActiveModal && <Modal />}
      <Sol />
      <ContentContainer />
    </AppContainer>
  );
}

export default connect()(App);
