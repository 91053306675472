import { AppState } from 'app/store';
import classNames from 'classnames';
import { MenuItemType } from 'models';
import { easeInOutQuint } from 'components/sol/utils';
import Ui from 'components/ui';
import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import LoadingIndicator from '../../components/loading-indicator';
import PageContainer, { PAGE_CONTAINER_ID } from './page-container';
import { MAIN_ID } from 'app/content-container';
import './index.scss';

const CONTENT_ID = 'page_content';
const HEADER_ID = 'header';
const WRAPPER_ID = 'page_wrapper';

const DELAY_MS = 100;
const OPEN_CONTENT_END_MS = 400;
const OPEN_CONTENT_START_MS = 0;
const SHOW_CONTENT_END_MS = 600;
const SHOW_CONTENT_START_MS = 300;

const ANIM_END_MS = SHOW_CONTENT_END_MS;

interface BasePageProps {
  activeMenuItem?: MenuItemType;
  header?: string;
  isLoading?: boolean;
  isNotificationActive: boolean;
}

interface BasePageState {
  isSolActive: boolean;
  showContent: boolean;
}

class BasePage extends React.Component<
  PropsWithChildren<BasePageProps>,
  BasePageState
> {
  private animationId?: number;
  private totalTimeElapsed: number = 0;
  private containerElement: HTMLElement | null = null;
  private contentElement: HTMLElement | null = null;
  private headerElement: HTMLElement | null = null;
  private mainElement: HTMLElement | null = null;
  private wrapperElement: HTMLElement | null = null;
  private previousFixedUpdateTimestamp?: number;
  private targetHeight: number = 0;
  private openDelayTimer?: NodeJS.Timeout;

  constructor(props: BasePageProps) {
    super(props);

    this.state = {
      isSolActive: props.activeMenuItem === MenuItemType.Sol,
      showContent: false,
    };
  }

  public componentDidMount(): void {
    this.openDelayTimer = setTimeout(() => {
      this.start();
    }, DELAY_MS);
  }

  public componentWillUnmount(): void {
    if (this.openDelayTimer) {
      clearTimeout(this.openDelayTimer);
    }
    cancelAnimationFrame(this.animationId || 0);
    window.removeEventListener('resize', this.onWindowResize);
  }

  public render() {
    const { activeMenuItem, header, isLoading } = this.props;
    const { isSolActive, showContent } = this.state;

    return (
      <PageContainer isSolActive={!!isSolActive}>
        <Ui.Header
          id={HEADER_ID}
          value={header || activeMenuItem?.toString() || ''}
        />
        <div className='content-wrapper' id={WRAPPER_ID}>
          <LoadingIndicator isLoading={!!isLoading} />
          <div
            className={classNames(showContent ? '' : 'hide')}
            id={CONTENT_ID}
          >
            {this.props.children}
          </div>
        </div>
      </PageContainer>
    );
  }

  private animateContent(duration: number, element: HTMLElement) {
    const calculatedOpacity = this.calculateSmoothedValue(
      duration,
      1,
      this.totalTimeElapsed - SHOW_CONTENT_START_MS
    );
    element.style.opacity = `${calculatedOpacity}`;
  }

  private animateContentWrapper(duration: number, element: HTMLElement) {
    const calculatedHeight = this.calculateSmoothedValue(
      duration,
      this.targetHeight,
      this.totalTimeElapsed - OPEN_CONTENT_START_MS
    );
    element.style.height = `${calculatedHeight.toString()}px`;

    const calculatedOpacity = this.calculateSmoothedValue(
      duration / 2,
      1,
      this.totalTimeElapsed
    );
    element.style.opacity = `${calculatedOpacity}`;
  }

  private calculateSmoothedValue(
    animDuration: number,
    targetValue: number,
    totalTimeElapsed: number
  ): number {
    return easeInOutQuint(totalTimeElapsed / animDuration) * targetValue;
  }

  // Use arrow function here to allow the use `this` in function called by requestAnimationFrame()
  private fixedUpdate = (timestamp: DOMHighResTimeStamp): void => {
    const timeElapsed: number =
      timestamp - (this.previousFixedUpdateTimestamp || timestamp);
    this.totalTimeElapsed += timeElapsed;

    if (!this.wrapperElement || !this.contentElement) {
      return;
    }

    if (
      this.totalTimeElapsed >= OPEN_CONTENT_START_MS &&
      this.totalTimeElapsed < OPEN_CONTENT_END_MS
    ) {
      this.animateContentWrapper(
        OPEN_CONTENT_END_MS - OPEN_CONTENT_START_MS,
        this.wrapperElement
      );
    }

    if (
      this.totalTimeElapsed >= SHOW_CONTENT_START_MS &&
      this.totalTimeElapsed < SHOW_CONTENT_END_MS
    ) {
      this.animateContent(
        SHOW_CONTENT_END_MS - SHOW_CONTENT_START_MS,
        this.contentElement
      );
      this.setState({
        showContent: true,
      });
    }

    if (this.totalTimeElapsed >= ANIM_END_MS) {
      cancelAnimationFrame(this.animationId || 0);
    } else {
      this.previousFixedUpdateTimestamp = timestamp;
      this.animationId = requestAnimationFrame(this.fixedUpdate);
    }
  };
 
  private onWindowResize = (): void => {
    if (!!this.wrapperElement) {
      const maxHeight = this.getMaxHeight();
      const minHeight = this.getMinHeight();
      const newHeight = maxHeight >= (this.contentElement?.offsetHeight || 0) ? minHeight : Math.min(maxHeight, this.wrapperElement.scrollHeight);
      this.wrapperElement.style.height = `${newHeight.toString()}px`;
    }
  };

  private start(): void {
    this.containerElement = document.getElementById(PAGE_CONTAINER_ID);
    this.contentElement = document.getElementById(CONTENT_ID);
    this.headerElement = document.getElementById(HEADER_ID);
    this.mainElement = document.getElementById(MAIN_ID);
    this.wrapperElement = document.getElementById(WRAPPER_ID);

    window.addEventListener('resize', this.onWindowResize);

    if (!!this.wrapperElement) {
      const maxHeight = this.getMaxHeight();
      const minHeight = this.getMinHeight();
      this.targetHeight = maxHeight > 0 ? Math.min(minHeight, maxHeight) : minHeight;
      this.wrapperElement.style.height = '0';
      this.wrapperElement.style.visibility = 'visible';
    }

    this.totalTimeElapsed = 0;
    this.animationId = requestAnimationFrame(this.fixedUpdate);
  }

  private getMaxHeight() {
    return (this.mainElement?.offsetHeight || 0)
      - (this.headerElement?.offsetHeight || 0)
      - (this.containerElement?.offsetTop || 0)
      - 10;
  }

  private getMinHeight() {
    return (this.contentElement?.offsetHeight || 0) + (this.contentElement?.offsetTop || 0);
  }
}

const mapStateToProps = ({
  activeMenuItem,
  activeModal,
  isLoading,
  notifierMessage,
}: AppState) => ({
  activeMenuItem: activeMenuItem.value,
  isLoading: isLoading.value && !activeModal.value,
  isNotificationActive: !!notifierMessage.queue,
});

export default connect(mapStateToProps)(BasePage);
