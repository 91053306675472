import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LinesProps } from "./types";

export const useLines = ({ nowrap, value }: LinesProps) => {
  const [wrappedLines, setWrappedLines] = useState<string[][]>([]);
  const linesWrapperRef = useRef<HTMLDivElement>(null);

  const measureWord = useCallback((wordHtml: string): number => {
    // Element resides in <body/>; hidden.
    const ruler = document.getElementById('ruler')!;
    ruler.innerHTML = wordHtml;
    return ruler.offsetWidth;
  }, []);
  const getAvailableWidth = useCallback(() => {
    if (!linesWrapperRef.current) return 0;
    const wrapper = linesWrapperRef.current;
    const firstElement = wrapper.firstElementChild;
    if (!firstElement) return 0;

    // Determines available width for displaying text per line.
    // Uses the "loading" Text Element during first render to determine
    // the correct margin/padding values.
    // Assumes all children have the same margin/padding values.
    const firstElementStyle = getComputedStyle(firstElement);
    return wrapper.offsetWidth
      - parseInt(firstElementStyle.paddingLeft, 10)
      - parseInt(firstElementStyle.paddingRight, 10)
      - parseInt(firstElementStyle.marginLeft, 10)
      - parseInt(firstElementStyle.marginRight, 10);
  }, [linesWrapperRef]);

  const spaceLength = useMemo(() => measureWord('&nbsp'), [measureWord]);

  useEffect(() => {
    const onWindowResize = () => {
      const availableWidth = getAvailableWidth();
      // Splits text value into paragraphs; limited to one if nowrap === true.
      const paragraphs = value?.split(/\n|\\n/, nowrap ? 1 : undefined).filter((p) => p !== '') ?? [];
      const wrappedParagraphs = paragraphs.map((p: string) => {
        if (nowrap) return [p];

        const splitWords = p.trim().split(' ');

        let lineIndex: number = 0;
        return splitWords.reduce(
          (lines: string[], word: string) => {
            const wordLength = measureWord(word);
            const lineLength = measureWord(lines[lineIndex]);

            if (lineLength <= 0) lines[lineIndex] = word;
            else {
              if (lineLength + spaceLength + wordLength >= availableWidth) {
                lineIndex++;
                lines[lineIndex] = word;
              } else {
                lines[lineIndex] += ` ${word}`;
              }
            }

            return lines;
          },
          [''] as string[]
        );
      });
      setWrappedLines(wrappedParagraphs);
    };

    onWindowResize();

    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [getAvailableWidth, measureWord, nowrap, spaceLength, value]);

  return {
    linesWrapperRef,
    wrappedLines,
  };
}
