import React, { ReactElement } from "react";
import { connect } from "react-redux";
import "./index.scss";
import Content from "pages";
import Notifier from "components/notifier";
import SolControl from "components/sol-control";
import Menu from "components/menu";

export const MAIN_ID = 'main';

const ContentContainer: React.FC = (): ReactElement => (
  <div className="content-container">
    <div id={MAIN_ID} className="main">
      <Menu />
      <Content />
      <Notifier />
    </div>
    <div className="footer">
      <SolControl />
    </div>
  </div>
);

export default connect()(ContentContainer);
