import Ui from "components/ui";
import React from "react";
import Planet from "./draw/planet";
import { Point } from "./draw/point";
import SolDraw from "./draw/solDraw";
import { fixCanvasDpi, getCanvas, setCanvasDimens } from "./utils";
import { CANVAS_ID, CANVAS_WRAPPER_ID, FPS_TARGET, SOL_DAYS_PER_REAL_SECONDS } from "./constants";
import { connect } from "react-redux";
import { AppState } from "app/store";
import { MenuItemType } from "models";
import { Dispatch } from "@reduxjs/toolkit";
import { addDisplayTime } from "slices/sol-slice";
import "./index.scss";

const PLANET_LABEL_OFFSET = 20;

interface SolProps {
  displayTimer?: number;
  isPrinting?: boolean;
  isSolActive?: boolean;
  isSolPaused?: boolean;
  addDisplayDate?: (time: number) => void;
}

interface SolState {
  planetHoveredOver: Planet | null;
}

class Sol extends React.Component<SolProps, SolState> {
  private pointer?: Point;
  private animationId?: number;
  private ctx?: CanvasRenderingContext2D;
  private displayedDateTimer: number = 0;
  private framerateBuffer: number = 0;
  private previousFixedUpdateTimestamp?: number;
  private solDraw: SolDraw;

  constructor(props: SolProps) {
    super(props);
    
    this.solDraw = SolDraw.instance;
    this.state = { planetHoveredOver: null };
  }

  public componentDidMount(): void {
    this.start();
    window.addEventListener('mousemove', this.onMouseMove);
  };

  public componentDidUpdate(prevProps: SolProps): void {
    if (prevProps.isSolActive !== this.props.isSolActive) {
      this.solDraw.updateCenterTarget(!!this.props.isSolActive);
    }
    if (this.props.displayTimer && prevProps.displayTimer !== this.props.displayTimer) {
      this.solDraw.resetDaysSinceInit(this.props.displayTimer);
    }
  }

  public componentWillUnmount(): void {
    cancelAnimationFrame(this.animationId || 0);

    window.removeEventListener('resize', this.resizeCanvas);
    window.removeEventListener('mousemove', this.onMouseMove);
  };

  public render = () => {
    const { isSolActive, isPrinting } = this.props;
    const { planetHoveredOver } = this.state;

    return (
      <div
        className="sol"
        id={CANVAS_WRAPPER_ID}
      >
        {isPrinting && <div className="print-flash" />}
        <div
          className="content"
        >
          <canvas
            id={CANVAS_ID}
            style={{
              height: window.innerHeight,
              position: "absolute",
              width: window.innerWidth,
            }}
          />
          {isSolActive && !!planetHoveredOver && (
            <div
              className="body-label"
              style={{
                left: planetHoveredOver.center.x + PLANET_LABEL_OFFSET,
                position: "absolute",
                top: planetHoveredOver.center.y + PLANET_LABEL_OFFSET,
              }}
            >
              <Ui.Lines
                nowrap
                value={planetHoveredOver.name.replace(/^\w/, (c: string) =>
                  c.toUpperCase()
                )}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  private fixedUpdate = (timestamp: DOMHighResTimeStamp): void => {
    const timeElapsed: number = this.props.isSolPaused ? 0 : timestamp - (this.previousFixedUpdateTimestamp || timestamp);

    this.displayedDateTimer += timeElapsed;

    const framesElapsedRaw: number = (timeElapsed / 1000) * FPS_TARGET + this.framerateBuffer;
    const framesElapsed: number = Math.floor(framesElapsedRaw);
    this.framerateBuffer = framesElapsedRaw - framesElapsed;

    this.ctx?.clearRect(
      0,
      0,
      this.ctx?.canvas.width,
      this.ctx?.canvas.height
    );

    this.solDraw.draw(this.ctx);
    this.solDraw.updateCenterAndPosition(framesElapsed);

    // Resets the "outer" animation loop on a Sol-daily basis
    const timerLimit: number = 1000 / SOL_DAYS_PER_REAL_SECONDS;
    if (this.displayedDateTimer > timerLimit) {
      const remainder = this.displayedDateTimer % timerLimit;
      const daysToAdd = (this.displayedDateTimer - remainder) / timerLimit;
      this.props.addDisplayDate?.(daysToAdd * 24 * 60 * 60 * 1000);
      this.displayedDateTimer = remainder;
    }

    const { isSolActive } = this.props;
    const planetHoveredOver = this.solDraw.getHoveredOverPlanet(this.pointer);
    this.setState({
      planetHoveredOver: (isSolActive && planetHoveredOver) || null,
    });

    this.previousFixedUpdateTimestamp = timestamp;
    this.animationId = requestAnimationFrame(this.fixedUpdate);
  };

  private onMouseMove = (e: MouseEvent): void => {
    // If pageX/Y aren't available and clientX/Y are,
    // calculate pageX/Y - logic taken from jQuery.
    // (This is to support old IE)
    const doc = document.documentElement;
    const body = document.body;

    const defaultX =
      e.clientX +
      (doc.scrollLeft || body.scrollLeft || 0) -
      (doc.clientLeft || body.clientLeft || 0);
    const defaultY =
      e.clientY +
      (doc.scrollTop || body.scrollTop || 0) -
      (doc.clientTop || body.clientTop || 0);

    this.pointer = {
      x: e.pageX !== null ? e.pageX : defaultX,
      y: e.pageY !== null ? e.pageY : defaultY,
    };
  };

  private resizeCanvas = (): void => {
    const canvas: HTMLCanvasElement = getCanvas();
    setCanvasDimens(canvas);
    this.solDraw.onCanvasResize(
      canvas.height,
      canvas.width,
      !!this.props.isSolActive,
    );
  };

  private start = (): void => {
    const canvas: HTMLCanvasElement = getCanvas();

    setCanvasDimens(canvas);

    this.ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    fixCanvasDpi(this.ctx);

    this.solDraw.initialize(
      canvas.height,
      canvas.width,
      !!this.props.isSolActive,
    );

    window.addEventListener('resize', this.resizeCanvas);

    this.animationId = requestAnimationFrame(this.fixedUpdate);
  };
}

const mapStateToProps = ({ activeMenuItem, sol }: AppState) => ({
  displayTimer: sol.displayTime,
  isPrinting: sol.isPrinting,
  isSolPaused: sol.isPaused,
  isSolActive: activeMenuItem.value === MenuItemType.Sol,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addDisplayDate: (time: number) => dispatch(addDisplayTime(time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sol);
