import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "app/store";
import { MenuItemType } from "models";

interface ActiveMenuItemState {
  value: MenuItemType;
}

const initialState: ActiveMenuItemState = {
  value: MenuItemType.Home,
};

export const activeMenuItemSlice = createSlice({
  name: "activeMenuItem",
  initialState,
  reducers: {
    setActiveMenuItem: (
      state: ActiveMenuItemState,
      action: PayloadAction<MenuItemType>
    ) => {
      state.value = action.payload;
    },
  },
});

export const { setActiveMenuItem } = activeMenuItemSlice.actions;

export const selectActiveMenuItem = (state: AppState) =>
  state.activeMenuItem.value;

export default activeMenuItemSlice.reducer;
