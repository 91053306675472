import { MenuItemType } from "models";
import Section from "components/section";
import Ui from "components/ui";
import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { setActiveMenuItem } from "slices/active-menu-item-slice";
import BasePage from "../base-page";

const EmptyPage: React.FC = (): ReactElement => {
  const dispatch = useDispatch();

  return (
    <BasePage header="That's odd">
      <Section>
        <Ui.Lines nowrap value="You seem lost. Let me help..." />
      </Section>
      <Section centered>
        <Ui.Button
          onClick={() => dispatch(setActiveMenuItem(MenuItemType.Home))}
          value="Go back"
        />
      </Section>
    </BasePage>
  );
};

export default EmptyPage;
