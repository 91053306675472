import Ui from "components/ui";
import React, { ReactElement } from "react";
import BaseModal from "../base-modal";
import { usePostModalContent } from "../hooks";
import { ReactComponent as ClapIcon } from "./icons/clap.svg";
import { ReactComponent as PinnedIcon } from "./icons/pinned.svg";
import { ReactComponent as UnpinnedIcon } from "./icons/unpinned.svg";
import { getDateString } from "utils";
import "./index.scss";
import classNames from "classnames";

export const PostModalContent: React.FC = (): ReactElement => {
  const { 
    authToken,
    claps,
    hasImageLoaded,
    incrementClap,
    isPostPinned,
    post,
    setHasImageLoaded,
    setIsPostPinned,
  } = usePostModalContent();
  return (
    <BaseModal
      menuItems={
        <>
          <Ui.Counter count={claps} />
          <Ui.Button disabled={!hasImageLoaded} icon={ClapIcon} onClick={incrementClap} iconOnly />
          <Ui.Button
            disabled={!hasImageLoaded || !authToken}
            icon={isPostPinned ? PinnedIcon : UnpinnedIcon}
            iconOnly
            onClick={() => setIsPostPinned(!isPostPinned)}
          />
        </>
      }
    >
      {post?.dataUrl ? (
        <a href={post.dataUrl} target="_blank" rel="noreferrer">
          <img
            alt={post.imageAlt}
            className={classNames('image', hasImageLoaded && 'show')}
            onLoad={() => setHasImageLoaded(!hasImageLoaded)}
            src={post.dataUrl}
          />
        </a>
      ) : (
        <div className="default" />
      )}
      <div className="text">
        <div className="header">
          <Ui.Lines alignEnd theme="uranus" nowrap value={post?.createdAt ? getDateString(new Date(post.createdAt)) : ''} />
          <Ui.Lines theme="uranus" value={post?.name} />
        </div>
        <div className="body">
          <Ui.Lines value={post?.body} />
        </div>
      </div>
    </BaseModal>
  );
};
