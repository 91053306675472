import Compress from 'compress.js';

export const convertImageToThumbnailBlob = async (image: File) => {
  const imgElement = document.createElement('img');
  imgElement.src = URL.createObjectURL(image);
  
  // Types don't seem to be right... js code for compress() doesn't seem to allow mutliple files
  // Converting to unknown types as a workaround for now
  const compress = new Compress();
  const result = await compress
    .compress(image as unknown as File[], {
      ...(imgElement.height > imgElement.width ? { maxWidth: 300 } : {}),
      ...(imgElement.width >= imgElement.height ? { maxHeight: 300 } : {}),
    });

  imgElement.remove();
  return result as unknown as Blob;
}
