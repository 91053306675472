export enum Direction {
  Backwards = -1,
  Forwards = 1,
}

export enum NavType {
  Reset = 0,
  Month,
  Year,
}

export interface CalendarMonthYear {
  month: number;
  year: number;
}

export type OnMonthYearSettle = (props: CalendarMonthYear) => void;
