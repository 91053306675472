import { Dispatch } from "react";
import { setIsLoading } from "slices/is-loading-slice";
import { setError, setSuccess } from "slices/notifier-message-slice";
import { Communicate, Configuration } from "utils";
import { CreatePostRequest, UpdatePostRequest, GetPostsRequest, ClapForPostRequest, GetPostHighlightsRequest, GetPostRequest, GetPostsMetadataRequest } from "./requests";
import { CreatePostResponse, GetPostResponse, GetPostsHighlightsResponse, GetPostsMetadataResponse, GetPostsResponse } from "./responses";
import { setHighlights, setMetadata, setPosts, updatePost } from "slices/post-slice";

const base = `${Configuration.apiUri}/posts`;

export default class PostService {
  public static async clap(request: ClapForPostRequest, dispatch: Dispatch<any>) {
    try {
      dispatch(setIsLoading(true));
      await Communicate.patch(`${base}/clap`, {
        id: request.post.id,
        claps: request.newClaps,  
      });
      dispatch(updatePost({
        ...request.post,
        claps: request.newClaps,
      }));
    } catch (m) {
      dispatch(setError(m as string[]));
    } finally {
      dispatch(setIsLoading(false));
    }
  }

  public static async create(request: CreatePostRequest, dispatch: Dispatch<any>) {
    try {
      dispatch(setIsLoading(true));
      const { data } = await Communicate.post<CreatePostResponse>(base, request);
      dispatch(setSuccess(data?.message ?? 'Success.'));
      dispatch(updatePost(data.post));
    } catch (m) {
      dispatch(setError(m as string[]));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  public static async getHighlights(request: GetPostHighlightsRequest, dispatch: Dispatch<any>) {
    try {
      dispatch(setIsLoading(true));
      const { data } = await Communicate.get<GetPostsHighlightsResponse>(`${base}/highlights`, request);
      dispatch(setHighlights({
        ...data,
      }));
    } catch (m) {
      dispatch(setError(m as string[]));
    } finally {
      dispatch(setIsLoading(false));
    }
  }

  public static async getMetadata(request: GetPostsMetadataRequest, dispatch: Dispatch<any>) {
    try {
      dispatch(setIsLoading(true));
      const { data } = await Communicate.get<GetPostsMetadataResponse>(`${base}/metadata`, request);
      dispatch(setMetadata({
        ...data,
      }));
    } catch (m) {
      dispatch(setError(m as string[]));
    } finally {
      dispatch(setIsLoading(false));
    }
  }

  public static async getPost(request: GetPostRequest, dispatch: Dispatch<any>) {
    try {
      dispatch(setIsLoading(true));
      const { data } = await Communicate.get<GetPostResponse>(base, request);
      dispatch(updatePost(data.post));
      return data.post;
    } catch (m) {
      dispatch(setError(m as string[]));
    } finally {
      dispatch(setIsLoading(false));
    }
  }

  public static async getPosts(request: GetPostsRequest, dispatch: Dispatch<any>) {
    try {
      dispatch(setIsLoading(true));
      const { data } = await Communicate.get<GetPostsResponse>(`${base}/paginated`, request);
      dispatch(setPosts({
        ...data,
        page: request.page,
      }));
    } catch (m) {
      dispatch(setError(m as string[]));
    } finally {
      dispatch(setIsLoading(false));
    }
  }

  public static async update(request: UpdatePostRequest, dispatch: Dispatch<any>) {
    try {
      dispatch(setIsLoading(true));
      await Communicate.patch(base, {
        ...request.newPost,
        id: request.post.id,
      });
      dispatch(updatePost({
        ...request.post,
        ...request.newPost,
      }));
    } catch (m) {
      dispatch(setError(m as string[]));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
}
