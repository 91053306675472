import Planet from "./planet";

export default class Orbit {
  static ORBIT_COLOR = "#B1AE96";
  static ORBIT_THICKNESS = 1.8;

  public color: string;
  public orbit: number;
  public orbitRadiusPixels: number;
  public radsPerDay: number;

  constructor(color: string, orbit: number, radsPerDay: number) {
    this.color = color;
    this.orbit = orbit;
    this.orbitRadiusPixels = 0;
    this.radsPerDay = radsPerDay;
  }

  public setOrbitRadiusPixels = (
    screenHeight: number,
    orbitRadiusMin: number
  ): void => {
    const orbitMax = screenHeight - Planet.DIAM_MAX;
    this.orbitRadiusPixels = Math.max(
      (orbitMax * this.orbit) / 2,
      orbitRadiusMin
    );
  };

  public orbitThickness = (): number => {
    const minThickness = Orbit.ORBIT_THICKNESS / 2;
    return minThickness + this.orbit * minThickness;
  };
}
