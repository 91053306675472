import classNames from "classnames";
import React from "react";
import { useLines } from "./hooks";
import { LinesProps } from "./types";
import "./index.scss";

export const Lines: React.FC<LinesProps> = ({
  fontSize,
  theme,
  ...restProps
}) => {
  const { linesWrapperRef, wrappedLines } = useLines(restProps);

  const getWrapLineClasses = (lineCount: number, lineIndex: number): string => {
    if (lineCount <= 1) return 'nowrap';

    if (lineIndex === 0) {
      return 'wrap top';
    } else if (lineIndex === lineCount - 1) {
      return 'wrap bottom';
    }

    return 'wrap middle';
  }

  return (
    <div className="ui wrapper lines" ref={linesWrapperRef}>
      {/* Displays an empty textbox when wrappedtext is empty so that elements are correctly measured. */}
      {wrappedLines.length > 0 ? (
        wrappedLines.map((paragraph: string[]) =>
          paragraph.map((line: string, ii: number) => (
            <span
              key={ii}
              className={classNames(
                'ui',
                'core',
                'lines',
                fontSize,
                theme,
                getWrapLineClasses(paragraph.length, ii),
              )}
            >
              {line}
            </span>
          ))
        )
      ) : (
        <span className="ui core lines placeholder" />
      )}
    </div>
  );
};
