import Ui from "components/ui";
import React, { ReactElement, useMemo } from "react";
import { ReactComponent as NewerIcon } from "./icons/newer.svg";
import { ReactComponent as OlderIcon } from "./icons/older.svg";
import { CardDeckProps } from "./CardDeck.types";
import { BaseModel } from "models/base";
import "./index.scss";

export const CardDeck = <T extends BaseModel>({
  cards,
  isLoading,
  onPageChange,
  page,
  pageLimit,
  renderCard,
  showControls,
  totalPages,
}: CardDeckProps<T>): ReactElement => {
  const blankCards = useMemo(() => ([
    ...Array(pageLimit)
  ].map((_, ii: number) => `blank_card_${ii + 1}`)), [pageLimit]);
  return (
    <div className="card-deck">
      <ul className="cards-list">
        {isLoading ? (
          blankCards.map((card: string) => (
            <li key={card}>
              <Ui.BlankCard />
            </li>
          ))
        ) : (
          <>
            {cards.map((card: T) => (
              <li key={card.id}>
                {renderCard(card)}
              </li>
            ))}
            {blankCards.slice(0, Math.max(0, pageLimit - cards.length)).map((card: string) => (
              <li key={card}>
                <Ui.BlankCard />
              </li>
            ))}
          </>
        )}
      </ul>
      {showControls && (
        <div className="controls">
          {onPageChange && (
            <Ui.Button
              disabled={page <= 1}
              icon={NewerIcon}
              iconOnly
              onClick={() => onPageChange(Math.max(1, page - 1))}
              value="Newer"
            />
          )}
          <Ui.Counter
            count={page}
            limit={totalPages}
            showLimit
          />
          {onPageChange && (
            <Ui.Button
              disabled={page >= totalPages}
              icon={OlderIcon}
              iconAlignEnd
              iconOnly
              onClick={() => onPageChange(Math.min(totalPages, page + 1))}
              value="Older"
            />
          )}
        </div>
      )}
    </div>
  );
};
