import { CANVAS_ID } from "./constants";
import { PlanetData } from "./draw/data";
import Orbit from "./draw/orbit";
import Planet from "./draw/planet";

export const fixCanvasDpi = (ctx: CanvasRenderingContext2D): void => {
  const scaledHeight: number = +getComputedStyle(ctx.canvas)
    .getPropertyValue("height")
    .slice(0, -2);

  const scaledWidth: number = +getComputedStyle(ctx.canvas)
    .getPropertyValue("width")
    .slice(0, -2);

  ctx.canvas.setAttribute(
    "height",
    (scaledHeight * window.devicePixelRatio).toString()
  );
  ctx.canvas.setAttribute(
    "width",
    (scaledWidth * window.devicePixelRatio).toString()
  );
};

export const getCanvas = (): HTMLCanvasElement =>
  document.getElementById(CANVAS_ID)! as HTMLCanvasElement;

// Used https://easings.net/
export const easeInOutQuint = (ratio: number) => {
  return ratio < 0.5
    ? 16 * Math.pow(ratio, 5)
    : 1 - Math.pow(-2 * ratio + 2, 5) / 2;
};

export const degInRad = (deg: number): number => (Math.PI * deg) / 180;

export const calculateRadsPerDay = (period: number): number =>
  period > 0 ? (2 * Math.PI) / period : 0;

export const constructPlanets = (daysSinceInitDate: number, data: PlanetData[]): Planet[] =>
  data.map(
    (obj: PlanetData) =>
      new Planet(
        obj.color,
        daysSinceInitDate,
        obj.diam,
        obj.initDeg,
        obj.name,
        obj.name === "sol"
          ? undefined
          : new Orbit(obj.color, obj.orbit, calculateRadsPerDay(obj.period))
      )
  );


export const setCanvasDimens = (canvas: HTMLCanvasElement): void => {
  canvas.height = window.innerHeight;
  canvas.width = window.innerWidth;
  canvas.style.height = `${canvas.height}px`;
  canvas.style.width = `${canvas.width}px`;
};
