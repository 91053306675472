import { DAYS_IN_WEEK } from "./constants";

export const calculateFirstWeekday = (currDayOfWeek: number, currDayOfMonth: number) => {
  const offset: number = (currDayOfMonth % DAYS_IN_WEEK) - 1;
  return offset <= currDayOfWeek
    ? currDayOfWeek - offset
    : DAYS_IN_WEEK + currDayOfWeek - offset;
};

export const getCalendarDigits = (daysInMonth: number, index: number): string => {
  const dayOfMonth: number = index + 1;
  if (dayOfMonth > daysInMonth) {
    return `${daysInMonth}`;
  }
  return `${dayOfMonth < 10 ? '0' : ''}${dayOfMonth}`;
};
