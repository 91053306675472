import Section from "components/section";
import Ui from "components/ui";
import React, { ChangeEvent, FormEvent, ReactElement, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import UserService from "services/user/userService";
import BasePage from "../base-page";
import { selectIsLoading } from "slices/is-loading-slice";

const LoginPage: React.FC = (): ReactElement => {
  const isLoading = useSelector(selectIsLoading);

  const dispatch = useDispatch();
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await UserService.login({ email, password }, dispatch);
  };

  return (
    <BasePage>
      <form onSubmit={submit}>
        <Section centered>
          <Ui.Text
            placeholder="email"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
            value={email}
          />
          <Ui.Password
            placeholder="password"
            value={password}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setPassword(e.target.value);
            }}
          />
        </Section>
        <Section centered>
          <Ui.Submit disabled={isLoading} />
        </Section>
      </form>
    </BasePage>
  );
};

export default connect()(LoginPage);
