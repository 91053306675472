import { AxiosRequestConfig } from "axios";
import { instance } from "./axios";
import { parseErrors } from "./parse-errors";
import { BaseRequest, BaseResponse } from "./types";

export async function del<TResponse extends BaseResponse>(route: string) {
  try {
    return await instance.delete<TResponse>(route);
  } catch (e) {
    throw parseErrors(e);
  }
}

export async function get<TResponse extends BaseResponse, TRequest extends BaseRequest = BaseRequest>(
  route: string,
  query?: TRequest,
) {
  try {
    const queryStr = query
      ? Object.entries(query)
          .map((q: [string, string]) => `${q[0]}=${q[1]}`)
          .join('&')
      : undefined;
    return await instance.get<TResponse>([route, queryStr].join("?"));
  } catch (e) {
    throw parseErrors(e);
  }
}

export async function patch<TResponse extends BaseResponse, TRequest extends BaseRequest = BaseRequest>(
  route: string,
  payload?: TRequest,
  config?: AxiosRequestConfig
) {
  try {
    return await instance.patch<TResponse>(route, payload, config);
  } catch (e) {
    throw parseErrors(e);
  }
}

export async function post<TResponse extends BaseResponse, TRequest extends BaseRequest = BaseRequest>(
  route: string,
  payload?: TRequest,
  config?: AxiosRequestConfig
) {
  try {
    return await instance.post<TResponse>(route, payload, config);
  } catch (e) {
    throw parseErrors(e);
  }
}
