import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import UserService from "services/user/userService";
import { setActiveMenuItem } from "slices/active-menu-item-slice";
import MenuItem from "./menu-item";
import { selectAuthToken } from "slices/auth-token-slice";
import "./index.scss";
import { MenuItemType } from "models";

const menuItemsArray: MenuItemType[] = Object.values(MenuItemType);
const columnRowCutoff = 1000;

interface MenuProps { }

const Menu: React.FC<MenuProps> = (): ReactElement => {
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken);
  const [forceIconOnly, setForceIconOnly] = useState<boolean>(false);
  
  const onWindowResize = useCallback(() => {
    setForceIconOnly(window.innerWidth <= columnRowCutoff);
  }, []);

  useEffect(() => {
    setForceIconOnly(window.innerWidth <= columnRowCutoff);
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [onWindowResize]);

  const onClick = useCallback(async (clickedMenuItem: MenuItemType) => {
    const isLogin = clickedMenuItem === MenuItemType.Login;
    const isLoggedIn = !!authToken;

    if (isLogin && isLoggedIn) {
      await UserService.logout(dispatch);
    } else {
      dispatch(setActiveMenuItem(clickedMenuItem));
    }
  }, [authToken, dispatch]);

  return (
    <div className="menu">
      {menuItemsArray.map((item: MenuItemType) => (
        <MenuItem
          key={item.valueOf()}
          {...{
            forceIconOnly,
            item,
            onClick,
          }}
        />
      ))}
    </div>
  );
};

export default connect()(Menu);
