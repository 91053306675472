
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { POST_PAGE_SIZE, selectPosts } from "slices/post-slice";
import PostService from "services/post/service";
import { getStartEndOfMonth } from "utils/get-start-end-of-month";
import { CalendarMonthYear } from "components/calendar/types";
import { ModalType, Post } from "models";
import { setActiveModal } from "slices/active-modal-slice";
import { selectIsLoading } from "slices/is-loading-slice";

export const useJournalPage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const allPosts = useSelector(selectPosts);
  
  const [currMonthYear, setCurrMonthYear] = useState<CalendarMonthYear>({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  const [page, setPage] = useState<number>(1);
  const [postTotal, setPostTotal] = useState<number>(0);

  const posts = useMemo(() => {
    if (!currMonthYear) return undefined;
    return allPosts[currMonthYear.year]?.[currMonthYear.month]?.[page] ?? {
      loaded: false,
      list: [],
    };
  }, [allPosts, currMonthYear, page]);

  const pageTotal = useMemo(() => (
    Math.max(1, Math.ceil((postTotal ?? POST_PAGE_SIZE) / POST_PAGE_SIZE))
  ), [postTotal]);

  const showModal = useCallback((postId: string): void => {
    const post = posts?.list.find((p: Post) => p.id === postId);
    if (!post) return;

    dispatch(
      setActiveModal({
        id: post.id,
        type: ModalType.Post,
        ...currMonthYear,
      })
    );
  }, [currMonthYear, dispatch, posts]);

  useEffect(() => {
    setPostTotal((prevValue: number) => (
      posts?.total ?? prevValue
    ));
  }, [posts?.total]);

  useEffect(() => {
    if (currMonthYear) setPage(1);
  }, [currMonthYear]);

  useEffect(() => {
    (async () => {
      if (!currMonthYear || !posts) return;

      if (!posts.loaded) {
        const { end, start } = getStartEndOfMonth(new Date(currMonthYear.year, currMonthYear.month - 1));
        await PostService.getPosts({
          endTimeUtc: end.toISOString(),
          page: page,
          pageSize: POST_PAGE_SIZE,
          startTimeUtc: start.toISOString(),
        }, dispatch);
      }
    })();
  }, [currMonthYear, dispatch, page, posts]);

  return {
    isLoading,
    page,
    pageTotal,
    posts: posts?.list ?? [],
    setCurrMonthYear,
    setPage,
    showModal,
  };
};
