import React, { PropsWithChildren } from "react";
import "./index.scss";
import classNames from "classnames";

const BAR_ID = 'loading_bar';
const BG_ID = 'loading_bg';

// Colors to loop through, sorted by hue.
const COLORS = [
  "#A48B8B",
  "#DECDBC",
  "#CBBDAC",
  "#958E7E",
  "#B1AE96",
  "#CFCFAF",
  "#B1AE96",
  "#90A3AA",
  "#9CB8A1",
  "#94A0AF",
];
const INTERVAL_MS = 500;

interface LoadingIndicatorProps {
  isLoading: boolean;
  rounded?: boolean;
}

interface LoadingIndicatorState {}

class LoadingIndicator extends React.Component<
  PropsWithChildren<LoadingIndicatorProps>,
  LoadingIndicatorState
> {
  private animationId?: number;
  private intervalTimeElapsed: number = 0;
  private barElement: HTMLElement | null = null;
  private bgElement: HTMLElement | null = null;
  private previousFixedUpdateTimestamp?: number;
  private targetWidth: number = 0;

  private barColorIndex: number;
  private bgColorIndex: number;

  constructor(props: LoadingIndicatorProps) {
    super(props);

    this.barColorIndex = Math.floor(COLORS.length * Math.random());
    this.bgColorIndex = this.barColorIndex < COLORS.length - 1
      ? this.barColorIndex
      : COLORS.length - 1;
  }

  public componentDidMount(): void {
    this.start();
  }
  
  public componentDidUpdate(): void {
    if (this.props.isLoading) this.continueAnimation(0);
    else this.endAnimation();
  }

  public componentWillUnmount(): void {
    this.endAnimation();
    window.removeEventListener('resize', this.onWindowResize);
  }

  public render() {
    const { rounded } = this.props;
    return (
      <div className={classNames('loading-indicator', { rounded })} id={BG_ID}>
        <div className="bar" id={BAR_ID} />
      </div>
    );
  }

  private calculateElementWidth(timeElapsed: number, element: HTMLElement | null) {
    const calculatedWidth = this.calculateValue(
      INTERVAL_MS,
      this.targetWidth,
      timeElapsed
    );
    if (!!element) element.style.width = `${calculatedWidth.toString()}px`;
  }

  // Used https://easings.net/
  private calculateValue(
    animDuration: number,
    targetValue: number,
    totalTimeElapsed: number
  ): number {
    const x = totalTimeElapsed / animDuration;
    return Math.floor((x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2) * targetValue);
  }

  private changeElementColor(color: string | 'transparent', element?: HTMLElement | null) {
    if (!!element) element.style.backgroundColor = color;
  }

  // Use arrow function here to allow the use `this` in function called by requestAnimationFrame()
  private fixedUpdate = (timestamp: DOMHighResTimeStamp): void => {
    const timeElapsed: number =
      timestamp - (this.previousFixedUpdateTimestamp || timestamp);
    this.intervalTimeElapsed += timeElapsed;

    if (!this.bgElement || !this.barElement) {
      return;
    }

    // Once animation reaches target timer replace background with bar color and set bar to new color.
    if (this.intervalTimeElapsed >= INTERVAL_MS) {
      this.bgColorIndex =
        this.barColorIndex < COLORS.length - 1 ? this.barColorIndex : COLORS.length - 1;
      this.barColorIndex =
        this.barColorIndex >= COLORS.length - 1 ? 0 : this.bgColorIndex + 1;

      this.intervalTimeElapsed = 0;

      this.changeElementColor(COLORS[this.barColorIndex], this.barElement);
      this.changeElementColor(COLORS[this.bgColorIndex], this.bgElement);
    }

    this.calculateElementWidth(this.intervalTimeElapsed, this.barElement);

    if (this.props.isLoading) this.continueAnimation(timestamp);
    else this.endAnimation();
  };
 
  private onWindowResize = (): void => {
    this.targetWidth = this.bgElement?.offsetWidth || 0;
  };

  private start(): void {
    this.barElement = document.getElementById(BAR_ID);
    this.bgElement = document.getElementById(BG_ID);

    this.targetWidth = this.bgElement?.offsetWidth || 0;
    window.addEventListener('resize', this.onWindowResize);

    if (this.props.isLoading) this.continueAnimation(0);
  }

  private continueAnimation(timestamp: number): void {
    this.changeElementColor(COLORS[this.barColorIndex], this.barElement);
    this.previousFixedUpdateTimestamp = timestamp;
    this.animationId = requestAnimationFrame(this.fixedUpdate);
  }

  private endAnimation(): void {
    this.changeElementColor('transparent', this.barElement);
    this.changeElementColor('transparent', this.bgElement);
    this.calculateElementWidth(0, this.barElement);
    cancelAnimationFrame(this.animationId || 0);
  }
}

export default LoadingIndicator;
