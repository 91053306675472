import Section from "components/section";
import Ui from "components/ui";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import BasePage from "../base-page";
import { selectPinned, selectPopular } from "slices/post-slice";
import PostService from "services/post/service";
import { CardDeck } from "components/card-deck";
import { setActiveModal } from "slices/active-modal-slice";
import { HighlightedPost, ModalType, Post } from "models";
import { getDateString } from "utils";

const HomePage: React.FC= (): ReactElement => {
  const dispatch = useDispatch();
  const pinned = useSelector(selectPinned);
  const popular = useSelector(selectPopular);
  
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [post, setPost] = useState<Post | undefined>(undefined);

  useEffect(() => {
    (async () => {
      await PostService.getHighlights({ take: 4 }, dispatch);
    })();
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(pinned.length === 0);
  }, [pinned.length]);

  useEffect(() => {
    if (!post) return;

    const postDate = new Date(post.createdAt);
    dispatch(setActiveModal({
      id: post.id,
      month: postDate.getMonth() + 1,
      type: ModalType.Post,
      year: postDate.getFullYear(),
    }));
  }, [dispatch, post])

  const loadPost = useCallback(async (postId: string) => {
    const loadedPost = await PostService.getPost({ id: postId }, dispatch);
    setPost(loadedPost);
  }, [dispatch]);

  return (
    <BasePage>
      <Section>
        <Ui.Lines
          value="
            Welcome; why don't you stay a while? I've pinned some posts of interest below for your convenience,
            and feel free to check my other pages out for more.\n
            Head on over to 'Sol' where you can take a screenshot of our solar system; a small remembrance of your visit :D
          "
        />
      </Section>
      <Section>
        <Ui.Lines value="Pinned" />
        <CardDeck
          cards={pinned}
          isLoading={isLoading}
          pageLimit={4}
          totalPages={1}
          page={1}
          renderCard={(post: HighlightedPost) => (
            <Ui.Card
              entityId={post.id}
              imgAlt={post.imageAlt}
              imgUrl={post.thumbnailUrl}
              label={getDateString(new Date(post.createdAt))}
              onClick={loadPost}
            />
          )}
        />
      </Section>
      <Section>
        <Ui.Lines value="Popular" />
        <CardDeck
          cards={popular}
          isLoading={isLoading}
          pageLimit={4}
          totalPages={1}
          page={1}
          renderCard={(post: HighlightedPost) => (
            <Ui.Card
              entityId={post.id}
              imgAlt={post.imageAlt}
              imgUrl={post.thumbnailUrl}
              label={getDateString(new Date(post.createdAt))}
              onClick={loadPost}
            />
          )}
        />
      </Section>
    </BasePage>
  );
}

export default connect()(HomePage);
