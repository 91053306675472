import classNames from "classnames";
import useClickOutside from "hooks/use-click-outside";
import React, { useRef, useState } from "react";
import { ReactComponent as ClosedIcon } from "./icons/closed.svg";
import { ReactComponent as OpenedIcon } from "./icons/opened.svg";
import { Controller, ControllerProps, FieldValues, Path } from "react-hook-form";

interface KeyValue {
  key: string;
  value: string;
}

interface SelectProps<U extends FieldValues> {
  control: ControllerProps<U>['control'];
  disabled?: boolean;
  items: KeyValue[];
  label: Path<U>;
  rules?: ControllerProps<U>['rules'];
}

export const Select = <U extends FieldValues>({
  disabled,
  items,
  label,
  control,
  rules,
}: SelectProps<U>) => {
  const [showItems, setShowItems] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside<HTMLDivElement>(ref, () => {
    setShowItems(false);
  });

  return (
    <Controller
      control={control as ControllerProps['control']}
      name={label}
      rules={rules as ControllerProps['rules']}
      render={({ field: { onBlur, onChange, value } }) => (
        <div
          className={classNames("ui core select", { disabled })}
          onClick={() => setShowItems(!showItems)}
          onBlur={onBlur}
          {...{ ref }}
        >
          <span className="selected-item">
            {items.find((i: KeyValue) => i.value === value)?.key || 'Select item'}
          </span>
          {showItems ? <OpenedIcon /> : <ClosedIcon />}
          <div className={classNames("items-wrapper", { open: showItems })}>
            <ul className="items-list">
              {items.map((item: KeyValue, ii: number) => (
                <li
                  className={classNames("select-item", {
                    selected:
                      typeof value !== 'undefined' && value === item.value,
                  })}
                  key={ii}
                  onClick={() => onChange(item.value)}
                >
                  <span>{item.key}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    />
  );
};
