import Orbit from "./orbit";
import { Point } from "./point";

export default class Planet {
  // Ratio value used to change planets' diameter ratio.
  // 0 maximizes the difference while 1 makes all diameters equal.
  static DIAM_RATIO: number = 0.3;
  static DIAM_MAX: number = 70;
  static OUTLINE_COLOR: string = "#E5E3C3";
  static OUTLINE_THICKNESS: number = 5;
  static PLANET_PADDING: number = 2;
  static SCREEN_RATIO: number = 0.001;

  public center: Point;
  public color: string;
  public diam: number;
  public initDeg: number;
  public name: string;
  public orbit?: Orbit;
  public rad: number;
  public radiusPixels: number;

  constructor(
    color: string,
    daysSinceInitDate: number,
    diam: number,
    initDeg: number,
    name: string,
    orbit?: Orbit
  ) {
    this.center = { x: 0, y: 0 } as Point;
    this.color = color;
    this.diam = diam;
    this.initDeg = initDeg;
    this.name = name;
    this.orbit = orbit;
    this.rad = 0;
    this.radiusPixels = 0;

    this.resetRad(daysSinceInitDate);
  }

  public addRad = (rad: number): void => {
    this.rad += rad % (2 * Math.PI);
  }

  public resetRad = (daysSinceInitDate: number): void => {
    const initRad = daysSinceInitDate * (this.orbit?.radsPerDay ?? 0);
    this.rad = initRad % (2 * Math.PI);
  }

  public setRadiusPixels = (screenHeight: number): void => {
    const diamMax = Planet.DIAM_MAX * Planet.SCREEN_RATIO * screenHeight;
    this.radiusPixels =
      (diamMax * (this.diam + (1 - this.diam) * Planet.DIAM_RATIO)) / 2;
  }

  public setCenter = (orbitCenter: Point): void => {
    const orbitRadiusPixels = this.orbit?.orbitRadiusPixels || 0;
    this.center = {
      x: orbitRadiusPixels * Math.sin(this.rad) + orbitCenter.x,
      y: orbitRadiusPixels * Math.cos(this.rad) + orbitCenter.y,
    };
  }
}
